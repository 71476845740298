import React from "react";
import './style.css'
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Missing = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const search = params.get('search');
    return (
        <main className="missing">
            {search ? (
                <div className="d-flex flex-column gap-2 align-items-center" style={{padding: '4rem 0'}}>
                    <FaSearch className="blue mx-auto" style={{fontSize: '10rem'}} />
                    <h1>Nothing Found</h1>
                    <p className="fs-5">Apologies, but no results were found. Perhaps searching will help find a related post.</p>
                </div>
            ) : (
                <div>
                    <div className="message mx-auto d-flex align-items-center justify-content-center">
                        <h1 className="blue text-center">NOT FOUND</h1>
                    </div>
                    <h2 className="text-center my-3"><strong>This is somewhat embarrassing, isn't it?</strong></h2>
                    <p className="fs-5 my-3">It looks like nothing was found at this location. Maybe try a search?</p>
                    
                </div>
            )}
            <form style={{width: '400px', height: '50px'}} className="mx-auto mb-5 position-relative">
                <input name="search" placeholder="Search for posts" className="w-100 h-100 p-3 fs-4" type="search" />
                <button type="submit" className="position-absolute h-100 rounded-circle top-0 end-0" style={{width: '50px'}}>
                    <FaSearch className="fs-4 text-dark" />
                </button>
            </form>
        </main>
    )
}

export default Missing