import React from "react";
// import Image from '../../image/img7.jpg'
import './style.css'


const Team = ({nom, role, image}) => {
    
    //const profileImage = require("../../image/img5.jpg");

    return (
        <div className="p-5 d-flex flex-column align-items-center gap-2 profile">
            <img src={image} className="image-profile rounded-pill" alt="profile" />
            <h3>{nom}</h3>
            <span className="blue">{role}</span>
        </div>
    )
}

export default Team