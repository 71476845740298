import React, { useState } from "react";
import Table from 'react-bootstrap/Table';
import logo from '../../image/test-tcf-logo.webp'
import './style.css'
import { Link } from "react-router-dom";
import { AiOutlineShoppingCart } from 'react-icons/ai';


const Panier = () => {
    const [items, setItems] = useState([
        {produit: "Standard", prix: 99.99},
        {produit: "Essentiel", prix: 79.99}
    ])
    const handleRemoveItem = (id) => {
        setItems(items.filter((item, ind) => ind !== id))
    }
    return(
        <main className="container panier my-5 px-3">
            {items.length === 0 ? (
                <div className="d-flex flex-column align-items-center gap-3" style={{marginBottom: '10rem'}}>
                    <AiOutlineShoppingCart style={{fontSize: '15rem'}} />
                    <h1>Votre panier est actuallement vide.</h1>
                    <p className="fs-5 text-center" style={{width: '52rem'}}>Before proceed to checkout you must add some products to your shopping cart. You will find a lot of interesting products on our "Shop" page. </p>
                    <Link to='/boutique' className="button rounded-pill">RETOUR A LA BOUTIQUE</Link>
                </div>
            ):(
                <div className="d-flex gap-5 flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        {} 
                        <Table bordered responsive="lg" className="flex-grow-1 w-100">
                            <thead>
                                <tr className="border-bottom py-2 fs-4">
                                    <th className="py-5"></th>
                                    <th></th>
                                    <th>PRODUIT</th>
                                    <th>PRIX</th>
                                    <th>QUANTITE</th>
                                    <th>SOUS-TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, id) => (
                                    <tr className="border-bottom" key={id}>
                                        <td className=""><span onClick={() => handleRemoveItem(id)}>x</span></td>
                                        <td className="">
                                            <img src={logo} style={{width: '80px', height: '80px'}} alt="logo" />
                                        </td>
                                        <td>{item.produit}</td>
                                        <td>${item.prix}</td>
                                        <td></td>
                                        <td className="">${item.prix}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-between code">
                            <div className="d-flex">
                                <input 
                                    type="text"
                                    placeholder="Code promo"
                                    className="border border-black p-2 fs-6 me-4"
                                />
                                <button className="button rounded-pill border-0 mt-2 fs-6">APPLIQUER LE CODE PROMO</button>
                            </div>
                            <button className="button fs-6 border-0 text-center">METTRE A JOUR LE PANIER</button>
                        </div>
                    </div>
                    <div>
                        <div className="total border border-black p-4 d-flex flex-column gap-2">
                            <h2>TOTAL PANIER</h2>
                            <div className="d-flex justify-content-between py-1">
                                <span className="fs-5"><strong>Sous-total</strong></span>
                                <span className="fs-5">$ 179,98</span>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between py-1">
                                <span className="fs-4"><strong>Total</strong></span>
                                <span className="blue fs-4"><strong>$ 179,98</strong></span>
                            </div>
                            <Link className="button rounded-pill text-center">VALIDER LA COMMANDE</Link>
                        </div>
                    </div>
                </div>
            )}
            
            
        </main>
    )
}

                            

export default Panier