import React from "react";
import Team from "../Team/Team";
import useWindowSize from '../../Hooks/useWindowSize';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './style.css'

const OurTeam = () => {
    const {width} = useWindowSize();
    const members = [
        {
            nom: "DOUNA",
            image: require("../../image/img12.jpeg"),
            role: "Fondateur/Adminstrateur"
        },
        {
            nom: "NARJESS",
            image: require("../../image/img13.jpeg"),
            role: "Fondateur/Adminstrateur"
        },
        {
            nom: "OKOU",
            image: require("../../image/img7.jpg"),
            role: "FORMATEUR"
        },
        {
            nom: "LEGRAND",
            image: require("../../image/img11.jpg"),
            role: "FORMATEUR"
        },
        {
            nom: "BEATRICE",
            image: require('../../image/img9.jpg'),
            role: "FORMATRICE"
        },
    ]
    return (
        <section className="ourteam">
            <div className="d-flex flex-column align-items-center head">
                <h2>FORMATION</h2>
                
            </div>
            <p className="fs-6"><strong>Rejoignez notre equipe de formation TCF CANADA pour assurer votre reussite!</strong></p>
            <div className="d-flex justify-content-center align-items-center">
                <Splide
                        className="slice"
                        style={{width: '100%', maxWidth: '1300px'}}
                        options={{
                            perPage:   width < 1025? 1 : 3 ,
                            arrows: true,
                            pagination: true,
                            drag: "free",
                            gap: "0.5rem",
                            autoplay: true, 
                            interval: 5000,
                            rewind: true
                        }}
                        data-aos="zoom-in-up"
                    >
                    {members.map((member) => (
                        <SplideSlide>
                            <Team {...member} />
                        </SplideSlide>
                    ))

                    }
                    </Splide>
                </div>
        </section>
    )
}

export default OurTeam