import React from "react";
import { FaUserPlus, FaKey, FaUser, FaPhone, FaLocationArrow } from "react-icons/fa";
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import FormControl from 'react-bootstrap/FormControl';
import { setToken } from "../../observable/tokenObservable";

//AuthContentRegister is the component in auth content used to register user
const AuthContentRegister = () => {

    const navigate = useNavigate();
    //const serverUrl = `http://13.48.177.86/api/v1`;
    const serverUrl = `https://backend.tcf-canada-training.com/api/v1`;

    const initialValues = {
        email: '',
        password: '',
        telephone: '',
        nom: '',
        prenom: '',
        ville: '',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
        telephone: Yup.string()
            .matches(/^\d+$/, 'Telephone must be a valid number')
            .min(9, 'Telephone must be at least 9 digits')
            .max(14, 'Telephone must be at most 14 digits')
            .required('Telephone is required'),
        nom: Yup.string().required('Name is required'),
        prenom: Yup.string(),
        ville: Yup.string(),
    });

    const onSubmit = async (values, { setSubmitting }) => {
        console.log(values);
        console.log("test");
        toast.dismiss();
        toast.loading('Connexion en cours...');
        try {
            const response = await axios.post(`${serverUrl}/auth/register`, values);
            sessionStorage.setItem("userId", response.data.id);
            sessionStorage.setItem("token",response.data.token)
            setToken(response.data.token)
            
            
            navigate('/');
        } catch (error) {
            toast.dismiss()
            if (error.response) {
                toast.error(`Une erreur est survenenue pendant l'execution de l'operation`);
            } else if (error.request) {
                toast.error('Une erreur est surevenue pendant l\'execution de l\'operation');
            } else {
                toast.error('Une erreur est surevenue pendant l\'execution de l\'operation');
            }
        }
        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, handleBlur, handleChange, errors, touched }) => (
                <Form className="d-flex flex-column gap-1" onSubmit={handleSubmit}>
                    <div>
                        <ErrorMessage name="email" component="div" className="red" />
                        <ErrorMessage name="prenom" component="div" className="red" />
                        <ErrorMessage name="nom" component="div" className="red" />
                        <ErrorMessage name="telephone" component="div" className="red" />
                        <ErrorMessage name="password" component="div" className="red" />
                        <ErrorMessage name="ville" component="div" className="red" />
                    </div>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="p-3 fs-4">@</InputGroup.Text>
                        <FormControl
                            name="email"
                            placeholder="E-mail"
                            aria-label="E-mail"
                            aria-describedby="basic-addon1"
                            className="fs-4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="p-3 fs-4"><FaKey /></InputGroup.Text>
                        <FormControl
                            name="password"
                            type="password"
                            placeholder="Mot de passe"
                            aria-label="Mot de passe"
                            aria-describedby="basic-addon1"
                            className="fs-4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                        />
                        
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="p-3 fs-4"><FaUserPlus /></InputGroup.Text>
                        <FormControl
                            name="prenom"
                            placeholder="Prenom"
                            aria-label="Prenom"
                            aria-describedby="basic-addon1"
                            className="fs-4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.prenom}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="p-3 fs-4"><FaUser /></InputGroup.Text>
                        <FormControl
                            name="nom"
                            placeholder="Nom"
                            aria-label="Nom"
                            aria-describedby="basic-addon1"
                            className="fs-4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.nom}
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="p-3 fs-4"><FaPhone /></InputGroup.Text>
                        <FormControl
                            name="telephone"
                            placeholder="Numero de Telephone"
                            aria-label="Telephone"
                            aria-describedby="basic-addon1"
                            className="fs-4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.telephone}
                        />
                        
                    </InputGroup>
                    
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className="p-3 fs-4"><FaLocationArrow /></InputGroup.Text>
                        <FormControl
                            name="ville"
                            placeholder="Ville"
                            aria-label="Ville"
                            aria-describedby="basic-addon1"
                            className="fs-4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ville}
                        />
                       
                    </InputGroup>
                    <button type="submit" className="w-100 rounded-pill py-3 px-3 fs-4">
                        S'inscrire
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default AuthContentRegister;
