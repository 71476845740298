import React from "react";
import './style.css'


const Subscription = () => {
    return (
        <div className="mx-3 my-5 subsription">
                </div>
    )
}

export default Subscription