import React from "react";
import Image from "../../image/img2.jpg";
import './style.css'
import { Link } from "react-router-dom";

const PolitiqueRetour = () => {
    return (
        <main>
            <section className="section w-100 position-relative mt-0">
                <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                    <div className="box5 text-white d-flex flex-column gap-2 m-5">
                        <h2><strong>Politique de retour et de remboursement</strong></h2>
                        <h3>Dernière mise à jour : 7 sepbtembre 2023</h3>
                        <p className="text-justify fs-5">Merci d’avoir acheté chez TCF-Canada. Si, pour une raison quelconque, vous n’êtes pas entièrement satisfait d’un achat, nous vous invitons à consulter notre politique en matière de remboursements et de retours. Cette politique de retour et de remboursement a été créée à l’aide du générateur de politique de retour et de remboursement. Les conditions suivantes s’appliquent à tous les produits que vous avez achetés avec nous.</p>
                    </div>
                </div>
            </section>
            <section className="m-5">
                <div className="box6 mx-auto">
                    <h2><strong>Interprétation et définitions</strong></h2>
                    <h3><strong>Interprétation</strong></h3>
                    <p>Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes.&nbsp;Les définitions suivantes ont le même sens, qu’elles apparaissent au singulier ou au pluriel.</p>
                    <h3>Définitions</h3>
                    <p>Aux fins de la présente politique de retour et de remboursement :</p>
                    <p><strong>La Société</strong>&nbsp;(appelée « la Société », « Nous », « Notre » ou « Nos » dans le présent Contrat) fait référence à TCF-Canada.</p>
                    <p><strong>Les produits</strong>&nbsp;font référence aux articles offerts en vente sur le Service.</p>
                    <p><strong>Les commandes</strong>&nbsp;signifient une demande de votre part d’acheter des produits auprès de nous.</p>
                    <p><strong>Le service</strong>&nbsp;fait référence au site Web.</p>
                    <p><strong>Le site Web</strong>&nbsp;fait référence à Réussir TCF Canada, accessible à partir de&nbsp;<Link href="https://tcf-canada.ca">https://tcf-canada.ca</Link></p>
                    <p><strong>Vous</strong>&nbsp;désignez la personne qui accède au Service ou l’utilise, ou la société, ou toute autre entité juridique au nom de laquelle cette personne accède ou utilise le Service, selon le cas.</p>
                    <h2>Vos droits d’annulation de commande</h2>
                    <p>Vous avez le droit d’annuler votre commande dans les 48 heurs sans donner de raison de le faire.</p>
                    <p>Le délai d’annulation d’une Commande est de 48 heurs à compter de la date à laquelle Vous avez effectué l’abonnement au pack.</p>
                    <p>Afin d’exercer Votre droit de rétractation, Vous devez Nous informer de votre décision au moyen d’une déclaration claire.&nbsp;Vous pouvez nous informer de votre décision en :</p>
                    <p>Par courriel :&nbsp;admin@formation-tcfcanada.com</p><p>En visitant cette page sur notre site Web :&nbsp;<Link href="https://formation-tcfcanada.com" target="_blank" rel="noopener">https://formation-tcfcanada.com</Link></p>
                    <p>Par téléphone : +15062536067&nbsp;</p>
                    <p>Nous Vous rembourserons au plus tard 14 jours à compter du jour où Nous recevons votre lettre de mécontentement.&nbsp;Nous utiliserons le même moyen de paiement que celui que vous avez utilisé pour la commande, et vous encourrez les frais d’un tel remboursement.</p>
                    <h2>N.B:&nbsp;</h2>
                    <div>Le partage de compte entre utilisateurs est strictement interdit et peut entraîner systématiquement une suppression irréversible de votre compte !</div>
                    <div>&nbsp;</div>
                    <h2>Contactez-nous</h2>
                    <p>Si vous avez des questions sur notre politique de retour et de remboursement, veuillez nous contacter :</p>
                    <p>Par courriel :&nbsp;admin@formation-tcfcanada.com</p>
                    <p>En visitant cette page sur notre site Web :&nbsp;<Link to="https://formation-tcfcanada.com" target="_blank" rel="noopener">https://formation-tcfcanada.com</Link></p>
                    <p>Par téléphone : +15062536067</p>						
                </div>
            </section>
        </main>
    )
}

export default PolitiqueRetour