import React from "react";
import Image from "../../image/img2.jpg";
import Image2 from "../../image/img15.webp"
import './style.css'
import { FaBuilding, FaCalendar, FaGraduationCap, FaHome, FaMoneyBill, FaRobot } from "react-icons/fa";
import { Link } from "react-router-dom";

const CorrectionExpressionOrale = () => {
    const tache2 = [
        {date: "Mars 2024", link: ""},
        {date: "Fevrier 2024", link: ""},
        {date: "Javier 2024", link: ""},
        {date: "Decembre 2023", link: ""},
        {date: "November 2023", link: ""},
        {date: "Octobre 2023", link: ""},
        {date: "Septembre 2023",link: ""},
        {date: "Aout 2023", link: ""},
        {date: "Juillet 2023",link: ""},
        {date: "Juin 2023", link: ""},
        {date: "Mai 2023", link: ""},
        {date: "Avril 2023", link: ""},
        {date: "Mars 2023", link: ""},
        {date: "Fevrier 2023", link: ""},
        {date: "Janvier 2023", link: ""},
    ]
    const tache3 = [
        {date: "Mars 2024", link: ""},
        {date: "Fevrier 2024", link: ""},
        {date: "Javier 2024", link: ""},
        {date: "Decembre 2023", link: ""},
        {date: "November 2023", link: ""},
        {date: "Octobre 2023", link: ""},
    ]
    return (
        <main>
            <section className="section w-100 position-relative mt-0">
                <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                    <div className="box5 text-white d-flex flex-column gap-2 m-5">
                        <h2 className="text-center"><strong>Correction des sujets d'expression orale</strong></h2>
                    </div>
                </div>
            </section>
            <section className="correction1 text-center p-5 mx-auto shadow border">
                <h1 className="blue h1">La tâche 2</h1>
                <h2 className="blue mb-5"><strong>Les catégories de la correction</strong></h2>
                <div className="d-flex flex-column gap-3">
                    {tache2.map((tache, id) => (
                        <div key={id} className="correction2 w-100 position-relative">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaCalendar className="fs-1" />
                                <h3><strong>{tache.date}</strong></h3>
                                <Link to={tache.link} className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className="correction1 text-center p-5 mx-auto shadow border">
                <h1 className="blue h1">La tâche 3</h1>
                <h2 className="blue mb-5"><strong>Les catégories de la correction</strong></h2>
                <div className="d-flex flex-column gap-3">
                    {tache3.map((tache, id) => (
                        <div key={id} className="correction2 w-100 position-relative">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaCalendar className="fs-1" />
                                <h3><strong>{tache.date}</strong></h3>
                                <Link to={tache.link} className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="d-flex flex-column mt-2">
                    <div className="d-flex flex-md-row flex-column">
                        <div className="correction2 w-100 w-md-50 position-relative my-3 mx-2">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaGraduationCap className="fs-1" />
                                <h3><strong>Education</strong></h3>
                                <Link to="" className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                        <div className="correction2 w-100 w-md-50 w-100 position-relative my-3 mx-2">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaHome className="fs-1" />
                                <h3><strong>Famille</strong></h3>
                                <Link to="" className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-md-row flex-column">
                        <div className="correction2 w-100 w-md-50  position-relative my-3 mx-2">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaBuilding className="fs-1" />
                                <h3><strong>Culture</strong></h3>
                                <Link to="" className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                        <div className="correction2 w-100 w-md-50  position-relative my-3 mx-2">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaBuilding className="fs-1" />
                                <h3><strong>Sante</strong></h3>
                                <Link to="" className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-md-row flex-column">
                        <div className="correction2 w-100 w-md-50  position-relative my-3 mx-2">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaRobot className="fs-1" />
                                <h3><strong>Technologies</strong></h3>
                                <Link to="" className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                        <div className="correction2 w-100 w-md-50  position-relative my-3 mx-2">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaRobot className="fs-1" />
                                <h3><strong>Environnement</strong></h3>
                                <Link to="" className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-md-row flex-column">
                        <div className="correction2 w-100  position-relative my-3 mx-2">
                            <img src={Image2} className="img w-100 h-100 position-absolute start-0 top-0" alt="landri" />
                            <div className="correction2-gradient position-absolute w-100 h-100 start-0 top-0 p-5 d-flex flex-column justify-content-center align-items-center text-white">
                                <FaMoneyBill className="fs-1" />
                                <h3><strong>Economique</strong></h3>
                                <Link to="" className="bg-none border border-white rounded-pill px-4 py-3 fs-4 text-white">Voir la correction</Link>
                            </div>
                        </div>
                        <div className="w-100"></div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default CorrectionExpressionOrale