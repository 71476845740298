import React, { memo } from 'react';
import './style.css'

const Timer = memo(({ timeLeft, totalTime, formatTime }) => {

    

    return (
        <div className='position-fixed bottom-0 left-0 w-100 text-dark bg-gray timer flex-column d-flex align-items-center justify-content-center py-4'>
            <p className='fs-5 bold'>Limite de Temps: {formatTime(timeLeft)}</p>
            <div className='bg-white rounded-pill w-100 mx-4'>
                <div className='bg-primary rounded-pill py-2' style={{width: `${timeLeft/totalTime*100}%`}}></div>
            </div>
        </div>
    );
});

export default Timer;
