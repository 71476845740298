import React from "react";
import './style.css';
import useWindowSize from '../../Hooks/useWindowSize';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { FaStar } from 'react-icons/fa';


const StarRating = ({ stars }) => {
    
    const starsArray = new Array(stars).fill(null);
  
    return (
      <div className="d-flex gap-2 yellow">
        {starsArray.map((_, index) => (
          <FaStar key={index} />
        ))}
      </div>
    );
  };

const Avis = () => {
    const {width} = useWindowSize();
    const avis = [
        {
            name: "Hiba LAZRAQ",
            stars: 5,
            email: "hiba.lazraq@yahoo.fr",
            avis: "Un grand merci a toute l'equipe, dirigee de main de maitre par l'administrateur M. Ayoub. Les cours dispenses sont reellement pertinents et repondent parfaitement aux attentes des candidats. Nous avons ete pleinement satisfaits de notre experience et recommandons vivement ce service. Qu'Allah vous accorde la reussite que vous meritez!"
        },
        {
            name: "Hiba LAZRAQ",
            stars: 5,
            email: "hiba.lazraq@yahoo.fr",
            avis: "Un grand merci a toute l'equipe, dirigee de main de maitre par l'administrateur M. Ayoub. Les cours dispenses sont reellement pertinents et repondent parfaitement aux attentes des candidats. Nous avons ete pleinement satisfaits de notre experience et recommandons vivement ce service. Qu'Allah vous accorde la reussite que vous meritez!"
        },
        {
            name: "Hiba LAZRAQ",
            stars: 5,
            email: "hiba.lazraq@yahoo.fr",
            avis: "Un grand merci a toute l'equipe, dirigee de main de maitre par l'administrateur M. Ayoub. Les cours dispenses sont reellement pertinents et repondent parfaitement aux attentes des candidats. Nous avons ete pleinement satisfaits de notre experience et recommandons vivement ce service. Qu'Allah vous accorde la reussite que vous meritez!"
        },
        {
            name: "Hiba LAZRAQ",
            stars: 5,
            email: "hiba.lazraq@yahoo.fr",
            avis: "Un grand merci a toute l'equipe, dirigee de main de maitre par l'administrateur M. Ayoub. Les cours dispenses sont reellement pertinents et repondent parfaitement aux attentes des candidats. Nous avons ete pleinement satisfaits de notre experience et recommandons vivement ce service. Qu'Allah vous accorde la reussite que vous meritez!"
        },
        {
            name: "Hiba LAZRAQ",
            stars: 5,
            email: "hiba.lazraq@yahoo.fr",
            avis: "Un grand merci a toute l'equipe, dirigee de main de maitre par l'administrateur M. Ayoub. Les cours dispenses sont reellement pertinents et repondent parfaitement aux attentes des candidats. Nous avons ete pleinement satisfaits de notre experience et recommandons vivement ce service. Qu'Allah vous accorde la reussite que vous meritez!"
        },
        {
            name: "Hiba LAZRAQ",
            stars: 5,
            email: "hiba.lazraq@yahoo.fr",
            avis: "Un grand merci a toute l'equipe, dirigee de main de maitre par l'administrateur M. Ayoub. Les cours dispenses sont reellement pertinents et repondent parfaitement aux attentes des candidats. Nous avons ete pleinement satisfaits de notre experience et recommandons vivement ce service. Qu'Allah vous accorde la reussite que vous meritez!"
        },
    ]

    return (
        <section className="mx-5">
            <h2 className="blue fs-1 text-center m-5">Avis de nos candidats</h2>
            <div className="d-flex align-items-center justify-content-center">
                <Splide
                    style={{width: width > 1300 ? '70%' : '100%'}}
                    options={{
                        perPage:   width < 1000? 1 : 3 ,
                        arrows: true,
                        pagination: true,
                        drag: "free",
                        gap: "3rem",
                    }}
                    data-aos="zoom-in-up"
                >
                {avis.map(({name, stars, email, avis}) => (
                    <SplideSlide>
                        <div className="border">
                            <div className="p-5 d-flex flex-column gap-2">
                                <h3>{name}</h3>
                                <StarRating stars={stars} />
                                <span>{email}</span>
                            </div>
                            <div className="p-5 bottom">
                                <p className="fs-5">{avis}</p>
                            </div>
                        </div>
                    </SplideSlide>
                ))

                }
                </Splide>
            </div>
        </section>
    )
}

export default Avis