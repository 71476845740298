import React from "react";
import { FaUserPlus } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import AuthContentLogin from "../AuthContentLogin/AuthContentLogin";
import AuthContentRegister from "../AuthContentRegister/AuthContentRegister";


const AuthContent = ({option, handleShow}) => {
    return (
        <div className="auth h-100 d-flex p-4 flex-column gap-3">
            <div className="d-flex text-center fs-4">
                <span className={option === 'connecter'? 'activeitem w-50 p-3 m-0' : 'item w-50 p-3 m-0'} onClick={() => handleShow({option: 'connecter'})}>se connecter</span>
                <span className={option === 'incrire'? 'activeitem w-50 p-3 m-0' : 'item w-50 p-3 m-0'} onClick={() => handleShow({option: 'incrire'})}>S'incrire</span>
            </div>

            {option==='connecter' && <AuthContentLogin handleShow={handleShow} />}
            
            {option==='incrire' && <AuthContentRegister /> }

            {option==='motpasse' && ( 
                <form className="d-flex flex-column gap-4">
                    <p className="fs-5 text-justify">Mot de passe perdu ? Veuillez saisir votre identifiant ou adresse e-mail. Vous recevrez un lien par e-mail pour créer un nouveau mot de passe.</p>
                    <InputGroup>
                    <InputGroup.Text id="basic-addon1" className="p-3 fs-4">
                        <FaUserPlus />
                    </InputGroup.Text>
                    <Form.Control
                        placeholder="Identifiant/e-mail"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        className="fs-4"
                    />
                    </InputGroup>
                    <button type="submit" className="w-100 rounded-pill py-3 px-3 fs-4">
                        ENVOYER L'EMAIL DE REINITIALISATION
                    </button>
                </form>
            )}
        </div>
    )
}

export default AuthContent