import React from 'react'
import './style.css'
import Image from "../../image/img2.jpg";
import { FaBook, FaRegEdit } from 'react-icons/fa';

const SujetExpressionOral = () => {
    return (
        <main>
            <section className="section w-100 position-relative mt-0" style={{height: '25rem'}}>
                <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                    <div className="box5 text-white d-flex flex-column gap-5 m-5 align-items-center">
                        <h2 className="text-center mb-2"><strong>Expression Orale Avril 2024</strong></h2>
                        <p className="text-center fs-5" style={{width: '100%', maxWidth: '700px'}}>Découvrez Les nouveaux sujets de l’expression orale qui se répètent. Pratiquez sur ces thèmes afin d'obtenir de bonnes notes.</p>
                    </div>
                </div>
            </section>
            <section className='container m-3 mx-auto mt-5'>
                <div className=' mt-2 shadow d-flex align-items-center justify-content-between gap-5 p-5'>
                    <div className='py-2 px-5 bg-primary text-white d-flex align-items-center rounded-4 flex-grow-1'>
                        <div className='sujet-circle border border-white rounded-circle self-align-startflex'></div>
                        <p className='text-center fs-4 mx-auto'>Tache 2</p>
                    </div>
                    <div className='py-2 px-5 bg-primary text-white d-flex align-items-center rounded-4 flex-grow-1'>
                        <div className='sujet-circle border border-white rounded-circle self-align-startflex'></div>
                        <p className='text-center fs-4 mx-auto'>Tache 3</p>
                    </div>
                </div>
                <div className='shadow mt-5 p-5 d-flex flex-column gap-3'>
                    <p className='text-start blue'><u>Sujets d'actualités Avril 2024</u></p>
                    <div className='attention p-4'>
                        <h3><strong>Attention !</strong></h3>
                        <p className='fs-5 text-start'>Ces contenus sont basés sur des sujets réels ! Ils ne sont pas les originaux. </p>
                    </div>
                    <div className='d-flex gap-5 fs-3 mt-3'>
                        <button className='flex-grow-1 text-white p-3 bg-button1 rounded-3'>Correction<FaRegEdit className='text-end' /></button>
                        <button className='flex-grow-1 text-white p-3 bg-button2 rounded-3'>Formation<FaBook /></button>
                    </div>
                </div>
                <div className='d-flex gap-2 py-5 align-items-center'>
                    <hr className='flex-grow-1 border-blue' />
                    <h3 className='blue fs-2'>Tache 2</h3>
                    <hr className='flex-grow-1 border-blue' />
                </div>
                <div className='shadow p-5'>
                        <div className='d-flex'>
                            <h3 className='fs-2'><strong>Partie 9</strong></h3>
                            <hr className='flex-grow-1' />
                        </div>
                        <div className='mt-5'>
                            <span className='blue p-3 fs-3 border border-primary rounded-2'><strong>Sujet 1</strong></span>
                            <div className='question border border-primary mt-4'>
                                <div className='gradient-sujet p-5'>
                                    <p>Je travaille dans une agence de tourisme, vous voulez faire de la randonnée. <span className='blue'>Posez moi des questions (organisation, activités, participants).</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <span className='blue p-3 fs-3 border border-primary rounded-2'><strong>Sujet 2</strong></span>
                            <div className='question border border-primary mt-4'>
                                <div className='gradient-sujet p-5'>
                                    <p>Je travaille dans une agence d’emploi. Vous venez de vous installer au Canada et vous cherchez un emploi. Posez moi des questions. </p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <span className='blue p-3 fs-3 border border-primary rounded-2'><strong>Sujet 3</strong></span>
                            <div className='question border border-primary mt-4'>
                                <div className='gradient-sujet p-5'>
                                    <p>Je suis votre ami(e). J’ai passé une semaine dans une nouvelle ville. Posez-moi des questions sur la ville. </p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <span className='blue p-3 fs-3 border border-primary rounded-2'><strong>Sujet 4</strong></span>
                            <div className='question border border-primary mt-4'>
                                <div className='gradient-sujet p-5'>
                                    <p>Je suis votre ami(e), j’ai participé à une émission télévisée.<span className='blue'> Posez moi des questions sur cette expérience (inscription, ambiance, etc.).</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <span className='blue p-3 fs-3 border border-primary rounded-2'><strong>Sujet 5</strong></span>
                            <div className='question border border-primary mt-4'>
                                <div className='gradient-sujet p-5'>
                                    <p>Je suis professeur de sport (gymnastique, yoga, etc.). J’organise un stage le week-end prochain. Vous êtes intéressé(e).<span className='blue'> Vous me posez des questions sur le stage (lieu, niveau, stagiaires, etc.).</span></p>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
            
        </main>
    )
}

export default SujetExpressionOral