import React from "react";
import Image from '../../image/img12.webp'
import './style.css'

const FormationSection2 = () => {
    return (
        <section className="mx-auto bggray box4 p-5 box3">
            <div className="d-flex flex-column gap-5 flex-md-row align-items-center">
                <div className="w-md-50 w-100 fs-5 d-flex flex-column gap-4 shadow-lg h-100 p-5">
                    <h5><b>Nous offrons un suivi particulier à chacun de nos candidats et nous nous rassurons de leur progrès dans les différentes épreuves du TCF.</b></h5>
                    <h5><b>Nos cours respectent l’équilibre entre la théorie et la pratique dans le but de mieux vous aider à préparer votre test.</b></h5>
                    <h5><b>Selon le déroulement de la formation qui vous sera précisé par votre formateur, selon vos avancés et vos besoins. Vous devrez prévoir de lui envoyer le travail personnel réalisé après chaque séance.</b></h5>						
                </div>
                <div className="w-md-50 w-100  h-100 shadow">
                    <img src={Image} className="w-100" alt="This is an image" />
                </div>
            </div>
        </section>
    )
}

export default FormationSection2