import React from "react";
import './style.css'

const Order = () => {
    return (
        <div>
            <h2 className="text-center my-5"><strong>Order</strong></h2>
            <div className="no-order my-5 mx-3 px-4 py-2"><span className="fs-5"><strong>No Orders have been made yet. Look for available Subscriptions</strong></span></div>
        </div>
    )
}

export default Order