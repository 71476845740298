import Accordion from 'react-bootstrap/Accordion';
import './style.css'
import Image from '../../image/img12.webp'

const CorrectionEpreuve1 = () => {
  return (
    <main>
        <section className="section w-100 position-relative mt-0">
            <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
            <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                <div className="box5 text-white d-flex flex-column gap-2 m-5">
                    <h2 className=""><strong>Politique De Confidentialité</strong></h2>
                    <h3 className="">Dern5ière mise à jour : 10 Août 2022</h3>
                    <p className="text-justify fs-5">Cette politique de confidentialité décrit nos politiques et procédures sur la collecte, l'utilisation et la divulgation de vos informations lorsque vous utilisez le service et vous informe sur vos droits en matière de confidentialité et sur la manière dont la loi vous protège. Nous utilisons vos données personnelles pour fournir et améliorer le service. En utilisant le Service, vous acceptez la collecte et l'utilisation d'informations conformément à la présente Politique de confidentialité. Cette politique de confidentialité a été créée à l'aide du modèle de politique de confidentialité.</p>
                </div>
            </div>
        </section>
        <section className='correction1 mx-auto shadow p-5'>
            <div className='correction3 p-4 mb-3'>
                <h4>Exemples de sujets et corrections : </h4>
                <p className='text-start'>La tâche 2 aborde un large éventail de sujets incluant la vie sociale, les voyages, les loisirs, les événements culturels, les problèmes environnementaux, la santé, les habitudes alimentaires et les questions d'actualité. Ces exemples réels, accompagnés de corrections, serviront de modèles pour les candidats désirant améliorer leurs performances dans cette épreuve.</p>
            </div>
            <div className='d-flex gap-3'>
                <h3 className='fs-2 blue'>Partie 10</h3>
                <hr className='flex-grow-1' />
            </div>
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Je travaille dans une agence de tourisme, vous voulez faire de la randonnée. Posez moi des questions (organisation, activités, participants).                </Accordion.Header>
                <Accordion.Body className='p-5'>
                    <strong>Quels sont les différents circuits de randonnée que vous proposez ?</strong><br />
                    <strong>Y a-t-il des randonnées adaptées aux débutants ?</strong><br />
                    <strong>Quel équipement est nécessaire pour participer à vos randonnées ?</strong><br />
                    <strong>Combien coûtent les différentes excursions ?</strong><br />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Je travaille dans une agence d’emploi. Vous venez de vous installer au Canada et vous cherchez un emploi. Posez moi des questions. </Accordion.Header>
                <Accordion.Body className='p-5'>
                    <strong>Quels sont les différents circuits de randonnée que vous proposez ?</strong><br />
                    <strong>Y a-t-il des randonnées adaptées aux débutants ?</strong><br />
                    <strong>Quel équipement est nécessaire pour participer à vos randonnées ?</strong><br />
                    <strong>Combien coûtent les différentes excursions ?</strong><br />
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        </section>
    </main>
  );
}

export default CorrectionEpreuve1;