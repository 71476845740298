import React from "react";
import ExpressionEcriteSection1 from "../../components/ExpressionEcriteSection1/ExpressionEcriteSection1";
import TarifFormation from "../../components/TarifFormation/TarifFormation";
import TarifTest from "../../components/TarifTest/TarifTest";
import Layout2 from "../../layout/Layout2";
import './style.css'

const ExpressionEcrite = () => {
    return (
        <main>
            <ExpressionEcriteSection1 />
            <Layout2 title={'TCF Canada Expression Écrite : Introduction'}>
                <div className="box1 pt-3">
                    <p>Cette épreuve d’expression écrite dure :<strong> 60 minutes</strong></p>
                    <p>Cette épreuve comporte <strong>3 tâches</strong></p>
                    <p>Vous êtes évalué sur vos capacités à :</p>
                    <ol>
                        <li>Communiquer un message de façon claire.</li>
                        <li>Donner les informations demandées.</li>
                        <li>Décrire, raconter, expliquer.</li>
                        <li>Justifier un choix, une position, une décision, etc.&nbsp;</li>
                        <li>Enchaîner des idées et faire preuve de cohérence dans votre discours.</li>
                        <li>Comparer deux points de vue.&nbsp;</li>
                        <li>Exprimer votre avis et l’argumenter.&nbsp;</li>
                        <li>Utiliser un vocabulaire et des structures adaptés à la tâche demandée.</li>
                        <li>Être capable de faire une synthèse et de reformuler.</li>
                    </ol>
                </div>
            </Layout2>
            <Layout2 title={'Modalités de passage :'}>
            <div class="pt-2">
                <p>L’épreuve d’expression écrite du <b>TCF&nbsp; CANADA </b>se passe sur ordinateur.</p>
                <p><span style={{color: '#ff0000'}}>ATTENTION&nbsp;</span>: le type de clavier que vous utiliserez ne sera pas nécessairement le même d’un centre d’examen à un autre. Assurez-vous de demander à votre centre d’examen quels claviers ils utilisent pour ne pas avoir de surprise le jour de l’examen et vous préparer au mieux.</p>						
            </div>
            </Layout2>
            <Layout2 title={'Critères d\'évaluation pour l\'épreuve d\'expression écrite (TCF CANADA) : '}>
                <div class="pt-2">
                    <p>Deux examinateurs évalueront vos compétences en expression écrite de manière indépendante l’un de l’autre.<br />Les examinateurs porteront une attention particulière à&nbsp;:</p>
                    <ul>
                        <li>votre niveau de langue (grammaire et syntaxe, variété et précision du vocabulaire…)&nbsp;;</li>
                        <li>votre aisance à structurer vos propos (cohésion, logique interne à vos écrits…)&nbsp;;</li>
                        <li>votre capacité à vous adapter à différentes situations de communication.</li>
                    </ul>						
                </div>
            </Layout2>
            <Layout2 title={'Savoir-faire attendus :'}>
                <div class="pt-2">
                    <p>Au cours de l’épreuve <b>d’expression écrite </b>du <b>TCF CANADA</b>, on vous demandera de&nbsp;:</p>
                    <ul>
                        <li>vous exprimer clairement sur un sujet donné ;</li>
                        <li>fournir des informations spécifiques en lien avec le sujet ;</li>
                        <li>argumenter, décrire, expliquer, reformuler ;</li>
                        <li>avoir une expression cohérente et organisée en portant une attention particulière au lexique, à la grammaire et à la syntaxe.</li>
                    </ul>						
                </div>
            </Layout2>
            <Layout2 title={'L\'évaluation de l\'épreuve d\'expression écrite :'}>
                <div class="pt-2">
                    <p>L'épreuve d’expression écrite est évaluée<strong>&nbsp;2 fois</strong> de manière totalement indépendante et en double aveugle (sans que l’un ou l’autre des deux évaluateurs n’ait connaissance des niveaux attribués par l’autre) par l’équipe de correcteurs de France Éducation international.</p>
                    <p>Ces correcteurs sont sélectionnés en fonction de leur expérience notable dans le domaine du Français langue Étrangère (FLE) et de l’évaluation, et après un test de sélection. Ils sont spécifiquement formés à la correction des épreuves d’expression écrite de toutes les déclinaisons du TCF par les agents du bureau des tests de France Éducation international qui assurent un suivi de la qualité du travail de chaque correcteur.&nbsp;</p>
                    <p><strong>Les critères d’évaluation de cette épreuve d’expression écrite sont d’ordre :&nbsp;</strong></p>
                    <ul>
                        <li><u>linguistique</u> (étendue du lexique, correction grammaticale, orthographe, degré d’élaboration des phrases…) ;</li>
                        <li><u>pragmatique</u> (cohérence et cohésion, développement thématique…) ;</li>
                        <li><u>sociolinguistique</u> (adéquation à la situation de communication).</li>
                    </ul>						
                </div>
            </Layout2>
            <Layout2 num={'1'} title={'TCF Canada Expression Écrite Tâche 1 :'}>
                <div class="pt-2">
                    <p>Rédaction d’un message, mail, annonce pour décrire, raconter et/ou expliquer, adressé à un ou plusieurs destinataires, dont le statut a été précisé dans la consigne. Minimum <strong>60 mots </strong>/maximum <strong>120 mots.</strong></p>
                    <h3 className="blue">TCF Canada Expression Écrite : La structure du mail Tâche 1 :</h3>
                    <p>Objet : (Mes vacances à Londres) par exemple.</p>
                    <ol>
                        <li> Salutations : (Salut Ayoub, comment vas-tu ?)</li>
                        <li> Objectif général du mail. (je t’écris ce mail pour te dire que je serai à Paris.)</li>
                        <li> Details de la circonstance&nbsp; I s’agit de Qui ? Quoi ? Quand ? où ? Avec qui ?</li>
                        <li> Exprimer les attentes concrètes. (Dépendamment de la consigne du sujet) J’aimerais que tu identifies des endroits chics que je Pourrais visiter.</li>
                        <li> formules de recommandation, promesse, remerciement.</li>
                        <li> formules d’Aurevoir (à&nbsp; bientôt, Porte toi bien, amicalement, cordialement, etc.)</li>
                    </ol>						
                </div>
            </Layout2>
            <Layout2 num={'02'} title={'TCF Canada Expression Écrite Tâche 2 :'}>
                <div class="pt-2">
                    <p>Rédaction d’un article de blog/ forum. À l’intention de plusieurs destinataires pour faire un compte rendu d’expérience ou un récit. Comptes rendus et récits seront accompagnés de commentaires, d’opinions ou d’arguments, en fonction d’un objectif. (Minimum <strong>120 mots</strong>/maximum <strong>150 mots.)</strong></p>
                    <blockquote className="border_left">
                        <p><span style={{color: '#333333'}}>Imaginez si on vous demande de rédiger un article de blog sur un métier qui vous passionne, en donnant envie aux gens de s’y intéresser.”</span></p>
                    </blockquote>
                    <h4>&nbsp;</h4>
                    <h4><span style={{color: '#135ef2'}}>TCF Canada Expression Ecrite : La structure de la Tâche 2 :</span></h4>
                    <ol>
                        <li>Il faut un titre attirant : Exemple : “un métier de nobles”. De nos jours les articles de blog sont très attrayants grâce à leurs titres. On a des titres comme 03 façons de reconquérir son ex. C’est attirant, ça donne envie de lire.</li>
                        <li>Après le titre, le Premier paragraphe porte simplement sur une présentation succincte et attirante de l’activité.</li>
                        <li>Ensuite vous partagez/ racontez votre propre expérience tout en utilisant les connecteurs chronologiques.</li>
                        <li>Enfin des recommandations. Vous recommandez à des personnes qui suivent votre blog de s’intéresser à l’activité que vous décrivez. Tout dépendra de la consigne.</li>
                    </ol>						
                </div>
            </Layout2>
            <Layout2 num={'03'} title={'TCF Canada Expression Écrite Tâche 3 :'}>
                <div class="d-flex flex-column gap-3">
                    <ul>
                        <li>Rédaction d’un article (pour un journal, un site Internet, etc.) qui compare deux points de vue portant sur un fait de société (1ère partie), exprimés dans deux documents courts et simples. Le candidat prend position sur le thème traité dans les deux documents (2ème partie).</li>
                        <li>Nombre de mots attendu : minimum <strong>120 mots</strong>/maximum <strong>180 mots</strong>.&nbsp;</li>
                        <li>On vous donne deux documents. Deux opinions. Au sujet d’une réalité. On vous demande de rédiger un court article.</li>
                        <li>L’article doit comprendre une première partie de <strong>40</strong> à <strong>60</strong> <b>mots </b>dans laquelle vous&nbsp; résumez le point de vue des deux documents.</li>
                        <li>l’article doit comprendre une deuxième partie de <strong>80</strong> à <strong>120</strong> mots dans laquelle vous donnez votre point de vue vis-à-vis du sujet dont parle les deux documents.</li>
                    </ul>
                    <h4><span className="blue">TCF Canada Expression Ecrite : La structure de la Tâche 3 :</span></h4>
                    <ol>
                        <li>Titre.</li>
                        <li>Point de vue des deux documents.</li>
                        <li>votre point de vue.</li>
                    </ol>
                    <p><strong>Si on vous donne par exemple deux points de vue sur l’utilisation d’Internet par les enfants, Vous pouvez commencer cette section avec des constructions comme :</strong></p>
                    <p>“De nos jours, la question de l’utilisation d’Internet par les enfants divise l’opinion public. Certains pensent que (Résumé Doc A) l’Internet rend les enfants paresseux et improductifs. Par contre, (Résumé Doc B d’autres trouvent en cet outil un véritable support de connaissances et de développement de l’imagination des enfants.”&nbsp; (40 à 60 mots)</p>
                    <p><strong>Et ensuite votre point de vue sur le sujet peut commencer par une construction comme :</strong></p>
                    <p>“A ce sujet, il est important de reconnaître qu’aujourd’hui il devient difficile d’appréhender la dynamique des sociétés actuelles en négligeant le rôle joué par Internet. Cet outil est devenu incontournable, voilà pourquoi il est nécessaire de prêter à cela un contrôle parental pour régulariser les heures de connexion des enfants et choisir les sites web appropriés à leurs âges. …etc.”&nbsp; &nbsp; &nbsp;(80 à 120 mots)</p>						
                </div>
            </Layout2>
            <TarifFormation />
            <TarifTest />
        </main>
    )
}

export default ExpressionEcrite