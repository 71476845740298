import React from "react";
import TarifFormation from "../../components/TarifFormation/TarifFormation";
import TarifTest from "../../components/TarifTest/TarifTest";

const Tarif = () => {
    return (
        <main>
            <section className="hero3 d-flex flex-column justify-content-center align-items-center mt-0 text-white">
                <h1>Tarif</h1>
            </section>
            <TarifFormation />
            <TarifTest />
        </main>
    )
}

export default Tarif