import React, {useState, useRef} from "react";
import { FaFacebook, 
        FaWhatsapp, 
        FaYoutube, 
        FaInstagram,
        FaBook, 
        FaMoneyBillAlt, 
        FaEnvelope, 
        FaBlog,
        FaMicrophone, 
        FaPen, 
        FaBookOpen, 
        FaHeadphones, 
        FaSignInAlt,
        FaSearch,  
        FaNewspaper, 
        FaTasks, 
        FaPenSquare,
        FaAngleRight,
        FaAngleDown,
        FaUser,
        FaSignOutAlt} from 'react-icons/fa';
import { MdMenu } from 'react-icons/md';
import './style.css';
import { Link, NavLink, useNavigate } from "react-router-dom";
import useAxiosFetch from "../../Hooks/useAxiosFetch";
import ImageLogo from "../../image/test-tcf-logo-e1661831845386.webp"


const NavbarMenu = () => {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const [isSubMenuVisible1, setSubMenuVisible1] = useState(false);
    const [isSubMenuVisible2, setSubMenuVisible2] = useState(false);
    const [isSubMenuVisible3, setSubMenuVisible3] = useState(false);
    const [isSubMenuVisible4, setSubMenuVisible4] = useState(false);
    const userString = sessionStorage.getItem('userId');
    console.log(userString)
    const {data} = useAxiosFetch(`accounts/get/${userString}`)
    const navigate = useNavigate();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    var user;
    if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
        user = data;
    }else{
        user = null;
    }

   const handleLogout = () => {
        sessionStorage.clear();
        //user = null;
        navigate('/login');
        navigate(0)
   } 
   

    const handleSetVisible = (setVisible) => {
        setVisible(true);
    };

    const handleSetHidden = (setVisible) => {
        setVisible(false);
    };

    const handleOpenNav = (e) => {
        e.stopPropagation();
        setIsNavOpen(true)
    }

    const handleCloseNav = (e) => {
        if (!ref1.current.contains(e.target) && !ref2.current.contains(e.target)) {
            setIsNavOpen(false)
          }
    }
    return (
        <header>
    <div className="navblue">
        <div className='mx-auto d-flex align-items-center nav1 text-white'>
            <span className='self-items-center fs-5 d-none d-xl-flex'>Bienvenue sur la plateforme TCF Canada</span>
            <div className="d-flex align-items-stretch gap-2 h-100">
                <ul className='d-xl-flex d-none navItems1 align-items-center gap-2 '>
                    <li>
                        <NavLink to='/formations'>
                            <FaBook/> <span>FORMATIONS</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/tarif'>
                            <FaMoneyBillAlt/> <span>TARIFS</span>
                        </NavLink>
                    </li>
                    <li>
                        <a href='/#contact'>
                            <FaEnvelope/> <span>CONTACTEZ-NOUS</span>
                        </a>
                    </li>
                    <li>
                        <Link to='/blog'>
                            <FaBlog/> <span>BLOG</span>
                        </Link>
                    </li>
                </ul>
                <div className="NavIcons h-100 d-flex gap-2 fs-5 justify-content-center">
                    <Link to='#'>
                        <span><FaFacebook /></span>
                    </Link>
                    <Link to='#'>
                        <span><FaInstagram /></span>
                    </Link>
                    <Link to='#'>
                        <span><FaYoutube /></span>
                    </Link>
                    <Link to='#'>
                        <span><FaWhatsapp /></span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
    <div className="nav2 d-flex top-0">
        <div className="menu d-flex d-xl-none" onClick={handleOpenNav}>
            <MdMenu className="fs-4 navicon"/> MENU
        </div>
        <div className="logo fs-4">
            <Link to="/"><img src={ImageLogo} alt="Logo" width={50}/></Link>
        </div>
        <nav className="navelement d-xl-flex d-none gap-4">
            <NavLink
                onMouseEnter={() => handleSetVisible(setSubMenuVisible1)}
                onMouseLeave={() => handleSetHidden(setSubMenuVisible1)}
                className="position-relative drop"
                to='/expression-ecrite'
            >
                <FaPen/> <span>EXPRESSION ECRITE<FaAngleDown/></span>
                {isSubMenuVisible1 && 
                    (
                    <div className="position-absolute start-0 droplist shadow d-flex flex-column gap-2">
                        <Link to='/expression-ecrite-sujets-dactualites'>
                            <FaNewspaper/> <span>Les sujets d'actualites</span>
                        </Link>
                        <Link to='/la-methodologie-dexpression-ecrite-tcf-canada'>
                            <FaTasks/> <span>Methodologie</span>
                        </Link>
                        <Link to='expression-ecrite-plate-forme-etape-1'>
                            <FaPenSquare/> <span>Plate-forme de redaction</span>
                        </Link>
                    </div>
                )
                }
            </NavLink>

            <NavLink
                onMouseEnter={() => handleSetVisible(setSubMenuVisible2)}
                onMouseLeave={() => handleSetHidden(setSubMenuVisible2)}
                className="position-relative drop"
                to='/expression-orale'
            >
                <FaMicrophone/> <span>EXPRESSION ORALE<FaAngleDown/></span>
                {isSubMenuVisible2 && (
                    <div className="position-absolute start-0 droplist shadow d-flex flex-column gap-2">
                        <Link to='/expression-orale/expression-orale-sujets-dactualites'>
                            <FaNewspaper/> <span>Les sujets d'actualites</span>
                        </Link>
                        <Link to='/la-methodologie-dexpression-orale-tcf-canada'>
                            <FaTasks/> <span>Methodologie</span>
                        </Link>
                        <Link to='/correction-des-sujets-dexpression-orale'>
                            <FaPenSquare/> <span>correction des sujets</span>
                        </Link>
                    </div>
                )
                }
            </NavLink>
            <NavLink to='/comprehension-ecrite'>
                <FaBookOpen/> <span>COMPREHENSION ECRITE</span>
            </NavLink>
            <NavLink to='/comprehension-orale'>
                <FaHeadphones/> <span>COMPREHENSION ORALE</span> 
            </NavLink>
            {user && (
                <NavLink to='/mon-compte'>
                    <FaUser /> <span>Bonjour, {user.nom}</span>
                </NavLink>
            )}
            {userString ? (<NavLink onClick={() => handleLogout()}><FaSignOutAlt/> <span>SE DECONNECTER</span></NavLink>):(
                <NavLink to='/login'>
                    <FaSignInAlt/> <span>SE CONNECTER</span> 
                </NavLink>
            )}
            
        </nav>
        <div className="search d-flex d-xl-none" onClick={handleOpenNav}>
            <FaSearch className="fs-4 navicon"/>
        </div>
    </div>
    {isNavOpen && (
        <div onClick={handleCloseNav} className="nav3 position-fixed top-0 start-0 vw-100 vh-100 d-xl-none d-flex justify-content-start" >
            <div className="bg-white navitems h-100 d-flex flex-column" data-aos="fade-right" data-aos-duration={300}>
                <NavLink to='/'>PAGE D'ACCEUIL</NavLink>
                <NavLink to='/formations'>FORMATION</NavLink>
                <NavLink to='/tarif'>TARIF</NavLink>
                <div ref={ref1} className="drop d-flex justify-content-between pt-0 pb-0 align-items-center">
                    <NavLink to='/expression-ecrite' className="border-0 ps-0">EXPRESSION ECRITE</NavLink>
                    {!isSubMenuVisible3 ? (
                        <div className="text-black" onClick={() => handleSetVisible(setSubMenuVisible3)}>
                            <FaAngleRight/>
                        </div>
                    ):(
                        <div className="bgblue" onClick={() => handleSetHidden(setSubMenuVisible3)}>
                            <FaAngleDown/>
                        </div>
                    )}
                </div>
                {isSubMenuVisible3 && (
                    <>
                        <Link className="subitem" to='/expression-ecrite-sujets-dactualites'>
                            <span>Les sujets d'actualites</span>
                        </Link>
                        <Link className="subitem" to='/la-methodologie-dexpression-ecrite-tcf-canada'>
                            <span>Methodologie</span>
                        </Link>
                        <Link className="subitem" to='/expression-ecrite-plate-forme-etape-1'>
                            <span>Plate-forme de redaction</span>
                        </Link>
                    </>
                )
                }
                <div ref={ref2} className="drop d-flex justify-content-between pt-0 pb-0 align-items-center">
                    <NavLink to='/expression-orale' className="border-0 ps-0">EXPRESSION ORALE</NavLink>
                    {!isSubMenuVisible4 ? (
                        <div className="text-black" onClick={() => handleSetVisible(setSubMenuVisible4)}>
                            <FaAngleRight/>
                        </div>
                    ):(
                        <div className="bgblue" onClick={() => handleSetHidden(setSubMenuVisible4)}>
                            <FaAngleDown/>
                        </div>
                    )}
                </div>
                {isSubMenuVisible4 && (
                    <>
                        <Link className="subitem" to='/expression-orale/expression-orale-sujets-dactualites'>
                            <span>Les sujets d'actualites</span>
                        </Link>
                        <Link className="subitem" to='/la-methodologie-dexpression-orale-tcf-canada'>
                            <span>Methodologie</span>
                        </Link>
                        <Link className="subitem" to='/correction-des-sujets-dexpression-orale'>
                            <span>correction des sujets</span>
                        </Link>
                    </>
                )
                }
                <NavLink to='/comprehension-ecrite'>COMPREHENSION ECRITE</NavLink>
                <NavLink to='/comprehension-orale'>COMPREHENSION ORALE</NavLink>
                <NavLink to='/blog'>BLOG</NavLink>
                <NavLink to='/#contact'>CONTACTEZ-NOUS</NavLink>
                {user && <NavLink to='/mon-compte'>BONJOUR, {user?.nom}</NavLink>}
                {userString ? (<NavLink onClick={() => handleLogout()}><FaSignOutAlt/> <span>SE DECONNECTER</span></NavLink>):(
                    <NavLink to='/login'>
                        <FaSignInAlt/> <span>SE CONNECTER</span> 
                    </NavLink>
                )}
                <div className="h-100">
                    <div className="d-flex h-100 gap-2 flex-wrap justify-content-center align-items-end">
                        <Link to='#'>
                            <span><FaFacebook /></span>
                        </Link>
                        <Link to='#'>
                            <span><FaInstagram /></span>
                        </Link>
                        <Link to='#'>
                            <span><FaYoutube /></span>
                        </Link>
                        <Link to='#'>
                            <span><FaWhatsapp /></span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )}
</header>


    )
}

export default NavbarMenu