import React, { useState } from "react";
import './style.css'
import { Link, NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { FaArrowRight, FaFirstOrder, FaHome, FaSignOutAlt, FaTeamspeak, FaUser } from "react-icons/fa";
import useWindowSize from "../../Hooks/useWindowSize";
import { MdMenu } from "react-icons/md";
import Profile from "../../components/Profile/Profile";
import Subscription from "../../components/Subscription/Subscription";
import Order from "../../components/Order/Order";



const MonCompte = () => {
    
    const id1 = sessionStorage.getItem('idComprehensionEcrite')
    const id2 = sessionStorage.getItem('idComprehensionOral')

    const userString = sessionStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;
    const {width} = useWindowSize();
    const [showNav, setShowNav] = useState(false);
    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear()
        navigate('/login')
    }

    const Bienvenue = () => {
        return (
             <div className="p-5">
                <h2 className="text-center"><strong>Bienvenue</strong></h2>
            </div>
        )
    }

    return (
        <main className="mon-compte">
           <div className="box mx-3 mt-5 border-0 ">
                <div className="gradient p-5">
                    <div className="d-flex flex-column align-items-center gap-2" style={{width: "200px"}}>
                        <span className="fs-5">Bonjour {user?.nom} {user?.prenom}</span>
                        <span className="fs-5 text-white">{user?.email}</span>
                        <span className="text-white">Member depuis {user?.date_creation}</span>
                        {}
                    </div>
                </div>
           </div>
           <div className="mx-3">
           <div className="menu ms-auto d-flex justify-content-center align-items-center bg-success d-sm-none">
                <MdMenu className="fs-1 text-white" onClick={() => setShowNav(!showNav)} />
            </div>
            
            {(showNav || width > 576) && (
                <div className="d-flex bg-dark flex-wrap">
                    <NavLink to='/mon-compte/bienvenue' className="py-2 px-4 fs-5 d-flex gap-2">
                        <FaHome className="fs-4" /> <span>Bienvenue</span>
                    </NavLink>
                    <NavLink to='/mon-compte/profile' className="py-2 px-4 fs-5 d-flex gap-2">
                        <FaUser className="fs-4" /> <span>Profile Details</span>
                    </NavLink>
                    <NavLink to='/mon-compte/subscription' className="py-2 px-4 fs-5 d-flex gap-2">
                        <FaTeamspeak className="fs-4" /> <span>Subscriptions</span>
                    </NavLink>
                    <NavLink to='/mon-compte/order' className="py-2 px-4 fs-5 d-flex gap-2">
                        <FaFirstOrder className="fs-4" /> <span>Orders</span>
                    </NavLink>
                    <div className="py-2 px-4 fs-5 d-flex gap-2 text-white" onClick={() => handleLogout()}>
                        <FaSignOutAlt className="fs-4" /> <span>LogOut</span>
                    </div>
                </div>
            )}
            
           </div>
           <Routes>
                <Route path='/bienvenue' Component={Bienvenue} />
                <Route path='/profile' Component={Profile} />
                <Route path='/subscription' Component={Subscription} />
                <Route path='/order' Component={Order} />
                <Route path='*' Component={Bienvenue} />
            </Routes>
            <div className="d-flex justify-content-center px-3">
                <Link to={`/tableau-de-bord-rf/${id1}/${id2}`} className="button p-4 rounded-5 text-center position-relative">S'entrainer <FaArrowRight className="position-absolute icon" /></Link>
            </div>
            
        </main>
    )
}

export default MonCompte