import React from "react";
import Image from "../../image/img2.jpg";
import './style.css';

const HeroLayout = ({children, bgImage}) => {
    return (
        <section className="section1 w-100 position-relative mt-0">
            <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
            <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                {children}
            </div>
        </section>
    )
}

export default HeroLayout