import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FaEnvelope, FaWhatsapp, FaPhone } from 'react-icons/fa';
import * as Yup from 'yup';
import './style.css'
import { Link } from "react-router-dom";




const validationSchema = Yup.object().shape({
  nom: Yup.string()
    .required('Le nom est requis'),
  prenom: Yup.string()
    .required('Le prénom est requis'),
  email: Yup.string()
    .email('Email invalide')
    .required('Email est requis'),
  telephone: Yup.string()
    .required('Le numéro de téléphone est requis'),
  question: Yup.string()
    .required('La question est requise')
});

const initialValues = {
  nom: '',
  prenom: '',
  email: '',
  telephone: '',
  question: ''
};


const Contact = () => {

    const handleSubmit = (values, { setSubmitting }) => {
        
        

        
        setSubmitting(false);
      };

    return (
        <div id="contact" className="contact-section d-flex justify-content-center position-relative" data-aos="zoom-in-up">
            <div className="mx-4 d-flex box flex-column flex-md-row gap-md-0 gap-5 border-0">
                <div className="form flex-grow-1 p-3">
                    <h3 className="blue fs-1 my-5 text-center">Contactez-nous</h3>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                    {({ isSubmitting }) => (
                        <Form className="d-flex flex-column gap-5 w-100 px-2 border-0">
                            <div className="d-flex flex-column gap-4">
                                <div className="d-flex gap-3 flex-column flex-sm-row">  
                                    <div className="d-flex flex-column gap-4 flex-grow-1">
                                        <label className="d-flex d-md-none" htmlFor="prenom">
                                            Prénom
                                            <span className="red">*</span>
                                        </label>
                                        <Field type="text" name="prenom" placeholder="Prénom" />
                                    </div>
                                    <div className="d-flex flex-column gap-4 flex-grow-1">
                                        <label className="d-flex d-md-none" htmlFor="nom">
                                            Nom
                                            <span className="red">*</span>
                                        </label>
                                        <Field type="text" name="nom" placeholder="Nom" />
                                    </div>
                                </div>
                                <ErrorMessage name="prenom" component="div" className="red"/>
                                <ErrorMessage name="nom" component="div" className="red"/>
                            </div>
                            <div className="d-flex flex-column gap-4">
                                <label htmlFor="email" className="d-flex d-md-none">
                                    Email
                                    <span className="red">*</span>
                                </label>
                                <Field type="email" name="email" placeholder="E-mail" />
                                <ErrorMessage name="email" component="div" className="red"/>
                            </div>
                            <div className="d-flex flex-column gap-4">
                                <label htmlFor="telephone" className="d-flex d-md-none">
                                    Téléphone
                                    <span className="red">*</span>
                                </label>
                                <Field type="text" name="telephone" placeholder="Téléphone" />
                                <ErrorMessage name="telephone" component="div" className="red"/>
                            </div>
                            <div className="d-flex flex-column gap-4">
                                <label htmlFor="question">
                                    Comment pouvons-nous vous aider ? 
                                    <span className="red">*</span>
                                </label>
                                <Field as="textarea" name="question" />
                                <ErrorMessage name="question" component="div" className="red" />
                            </div>
                            <button type="submit" disabled={isSubmitting}>
                                Envoyer
                            </button>
                        </Form>
                    )}
                    </Formik>
                </div>
                <div className="bgblue text-white d-flex align-items-center info flex-grow-1 p-5">
                    <div className="ms-5 d-flex flex-column gap-md-5 gap-2 fs-3">
                        <h3 className="fs-2">INFO:</h3>
                        <div>
                            <Link to="" className="text-white">
                                <FaEnvelope />:
                                <span className="fs-4">contact@tcf-canada-training.com</span>
                            </Link>
                        </div>
                        <div> 
                            <Link to="https://wa.me/+237693329888" target="blank" className="text-white">
                                <FaWhatsapp />:
                                <span> +237693329888</span>
                            </Link>
                        </div>
                        <div>
                            <FaPhone />:
                            <span> +237693329888</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact