import React from "react";
import Image from '../../image/img13.webp';
import './style.css';

const FormationSection3 = () => {
    return (
        <section className="mx-auto bggray box4 p-5 box3">
            <div className="d-flex flex-column gap-5 flex-md-row align-items-center">
                <div className="w-md-50 w-100  h-100 shadow">
                    <img src={Image} className="w-100" alt="This is an image" />
                </div>
                <div className="w-md-50 w-100 fs-5 d-flex flex-column gap-4 shadow-lg h-100 p-5">
                    <p className="fs-5 text-justify">Francophone ou non-francophone ayant besoin pour des raisons personnelles, universitaires ou professionnelles, d’évaluer ses connaissances en français, de façon fiable et reconnue.</p>
                    <p className="fs-5 text-justify">Français langue étrangère ou langue secondaire dont le niveau d’entrée est supérieur à +B1</p>
                    <p className="fs-5 text-justify">Un test de positionnement est obligatoire (Gratuit)</p>
                    <p className="fs-5 text-justify">Disposer d’une autonomie informatique et d’un ordinateur avec webcam et connexion internet</p>						
                </div>
            </div>
        </section>
    )
}

export default FormationSection3