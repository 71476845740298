import React from "react";
import {FaBook} from "react-icons/fa"
import Image from '../../image/img11.webp'
import './style.css'
import { Link } from "react-router-dom";

const FormationSection1 = () => {
    return (
        <section className="d-flex flex-column align-items-center mx-5">
            <div className="d-flex flex-column align-items-center head">
                <h2>OBJECTIF DE LA Formation</h2>
                <div>
                    <FaBook/>
                </div>
            </div>
            <div className="bggray p-5 box3 m-3 shadow-lg">
                <div>
                    <p className="text-justify">Vous envisagez d’immigrer au Canada ou de poursuivre des études supérieures dans une université canadienne ? Le Test de Connaissance du Français pour le Canada (TCF Canada) est une exigence pour prouver votre niveau de français auprès des institutions canadiennes. Nous proposons une formation en ligne pour vous aider à réussir le TCF Canada avec succès.</p>
                    <p className="text-justify">Notre formation en ligne est dispensée par des professeurs de français expérimentés et qualifiés qui connaissent les exigences du TCF Canada. Nous vous offrons une formation personnalisée pour vous aider à améliorer vos compétences en français et à vous préparer au test. Nos cours en ligne vous permettent d’apprendre à votre propre rythme et d’adapter la formation à votre emploi du temps. Notre formation en ligne est conçue pour vous donner une compréhension approfondie des différents aspects du test TCF Canada, y compris la compréhension orale, la compréhension écrite, l’expression orale et l’expression écrite. Nous vous proposons des exercices pratiques, des simulations de test et des évaluations régulières pour vous aider à évaluer vos progrès et à améliorer vos compétences en français.</p>
                    <p className="text-justify">En suivant notre formation en ligne, vous augmenterez considérablement vos chances de réussir le TCF Canada et de réaliser vos projets d’immigration ou d’études supérieures au Canada. Nous sommes là pour vous aider à atteindre vos objectifs et à réaliser vos rêves.</p>
                    <p className="text-justify">N’attendez plus pour vous inscrire à notre formation en ligne TCF Canada. Rejoignez notre communauté de candidats motivés et bénéficiez de notre expertise en français pour réussir le TCF Canada.</p>						
                </div>
                <div className="d-flex flex-column gap-5 flex-md-row align-items-center">
                    <ul className="w md-50 w-100 fs-5 d-flex flex-column gap-2">
                        <li>Présentation de l’examen du&nbsp;<b>TCF&nbsp;</b>(test de connaissance du français).</li>
                        <li>Un programme adapté aux besoins du Candidat.</li>
                        <li>Identification de ses forces et ses faiblesses.</li>
                        <li>Familiarisation avec la forme et le contenu générale des épreuves.</li>
                        <li>La maitrise des structures de l’expression écrite et de l’expression orale (techniques, astuces, simulations, etc.).</li>
                        <li>Entrainement intensif des exercices représentatifs et application des acquis.</li>
                        <li>Apprentissage de la gestion du temps.</li>
                        <li>Passation d’un examen blanc avec une correction et des conseils individualisés.</li>
                    </ul>
                    <div className="w-md-50 w-100">
                        <img src={Image} className="w-100" alt="This is an image" />
                    </div>
                </div>
            </div>
        </section>
    )
}


export default FormationSection1