import React from "react";
import './style.css'
import { Link } from "react-router-dom";

const HomeSection1 = () => {
    return (
        <section className="mt-3 position-relative section1" data-aos="zoom-in-up">
            <div className="position-relative d-flex align-items-center justify-content-center w-100 h-100 gradient">
                <div className="text-white d-flex flex-column gap-4 align-items-center">
                    <h1>Se préparer au TCF canada</h1>
                    <p>Plate-forme spécialisée dans la préparation au TCF Canada<br />
                    Tests à conditions réelles .</p>
                    <div className="d-flex flex-column flex-lg-row gap-5">
                        <Link to='/comprehension-ecrite' className="button">Comprehension Ecrite</Link>
                        <Link to='/comprehension-orale' className="button">Comprehension Orale</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeSection1