import React, { useState } from "react";
import './style.css';
import { FaHome,
        FaFacebook, 
        FaWhatsapp, 
        FaYoutube, 
        FaInstagram,
        FaUserPlus,
        FaLock,
        FaSignInAlt,  
        FaBookOpen,
        FaHeadphones,
        FaMicrophone,
        FaPen,
        FaBalanceScale,
        FaGavel,
        FaBlog,
        FaPlus} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Image from "../../image/img4.webp"
import Authentification from "../Authentification/Authentification";

const Footer = () => {
    const routPath = useLocation().pathname
    const [option, setOption] = useState('')
    const [show, setShow] = useState(false)
    const handleShow = ({option}) => {
        setShow(true)
        setOption(option)
    }
    const handleClose = () => {
        setShow(false)
        setOption('')
    }
    return (
        <> 
            <Authentification 
                show={show}
                option={option}
                handleClose={handleClose} 
                handleShow={handleShow}
            />
            <footer data-aos="zoom-in-up">
                <div className="bgblue p-3 d-flex flex-lg-row flex-column justify-content-between">
                    <div className="foot-col">
                        <h3>Les Pages</h3>
                        <Link to='/' className="d-flex gap-3 pt-3 pb-3">
                            <FaHome /> Page d'accueil
                        </Link>
                        <Link to='comprehension-ecrite' className="d-flex gap-3 pt-3 pb-3">
                            <FaBookOpen /> Comprehension Ecrite
                        </Link>
                        <Link to='comprehension-orale' className="d-flex gap-3 pt-3 pb-3">
                            <FaHeadphones /> Comprehension Orale
                        </Link>
                        <Link to='expression-ecrite' className="d-flex gap-3 pt-3 pb-3">
                            <FaPen />Expression Ecrite
                        </Link>
                        <Link to='expression-orale' className="d-flex gap-3 pt-3 pb-3">
                            <FaMicrophone /> Expression Orale 
                        </Link>
                        <Link to='/politique-de-retour' className="d-flex gap-3 pt-3 pb-3">
                            <FaBalanceScale /> Politique de retoure et de remboursement
                        </Link>
                    </div>
                    <div className="foot-col">
                        <Link to='/politique-de-confidentialite' className="d-flex gap-3 pt-3 pb-3">
                            <FaGavel /> Politique de confidentialite
                        </Link>
                        <Link to='/blog' className="d-flex gap-3 pt-3 pb-3">
                            <FaBlog /> Blog
                        </Link>
                        <Link to='/tcf-canada-test-de-connaissance-du-francais-pour-le-canada' className="d-flex gap-3 pt-3 pb-3">
                            <FaHome /> TCF Canada
                        </Link>
                        {routPath === '/login' ? (
                            <>
                                <h3 className="my-3">Parametre du compte</h3>
                                <div className="authcontainer d-flex flex-column fs-4">
                                    <div className="py-4 px-5" onClick={() => handleShow({option: 'connecter'})}>
                                        <FaSignInAlt className="me-3"/> <span>se connecter</span>
                                    </div>
                                    <div className="py-4 px-5" onClick={() => handleShow({option: 'incrire'})}>
                                        <FaUserPlus className="me-3"/> <span>S'incrire</span>
                                    </div>
                                    <div className="py-4 px-5" onClick={() => handleShow({option: 'motpasse'})}>
                                        <FaLock className="me-3"/> <span>Mot de passe</span>
                                    </div>
                                    
                                </div>
                            </>
                        ):(
                            <Link to='/pourquoi-choisir-notre-plate-forme-tcf-canada' className="d-flex gap-3 pt-3 pb-3 border-0">
                                <FaPlus />Pouvoir nous choisir
                            </Link>
                        )}
                        
                    </div>
                    <div className="foot-col d-flex flex-column gap-2">
                        <h3>Nos Réseaux Sociaux</h3>
                        <div className="navicons d-flex gap-2">
                            <Link to='#' className="bg-danger">
                                <span><FaYoutube /></span>
                            </Link>
                            <Link to='#' className="bg-primary" >
                                <span><FaFacebook /></span>
                            </Link>
                            <Link to='https://wa.me/+237693329888' className="bg-success">
                                <span><FaWhatsapp /></span>
                            </Link> 
                            <Link to='#' className="bg-danger">
                                <span><FaInstagram /></span>
                            </Link>
                            
                        </div>
                        <h3>À propos de nous</h3>
                        <p className="fs-5">Plate-forme dédiée à la préparation au TCF Canada avec des tests en conditions réelles.</p>
                    </div>
                    <div className="foot-col d-flex flex-column gap-4 align-items-center">
                        <div>
                            <h4>Partagez ce code avec votre réseau pour rejoindre </h4>
                            <span>notre communauté :</span>
                        </div>
                        <img src={Image} alt='reseau' />
                    </div>
                </div>
                <div className="p-3 text-center" >
                    <span><Link to="#" className="text-black">TCF CA TEST</Link> 2024 | Tous les droits sont réservés. v2</span>
                    <div className="d-flex justify-content-center gap-2">
                    </div>
                </div>
            </footer>
        </>
    )
}


export default Footer