import React from "react";
import TarifFormation from "../../components/TarifFormation/TarifFormation";
import TarifTest from "../../components/TarifTest/TarifTest";
import Layout2 from "../../layout/Layout2";
import './style.css'
import HeroLayout from "../../layout/HeroLayout";
import { Link } from "react-router-dom";

const ComprehensionOrale = () => {

    const id1 = sessionStorage.getItem('idComprehensionEcrite')
    const id2 = sessionStorage.getItem('idComprehensionOral')

    return (
        <main>
            <HeroLayout>
                <div className="d-flex flex-column gap-5 text-white align-items-center">
                    <h1 className="fs-1">Se préparer au TCF (Compréhension Orale)</h1>
                    <p className="fs-3" style={{maxWidth: '75rem', width: '90%'}}>Afin de vous préparer au mieux au TCF et booster votre score, nous mettons à votre disposition des tests qui réunissent les mêmes conditions que lors d’une session officielle</p>
                    <div className="d-flex gap-4 flex-md-row flex-column">
                        <Link className="button rounded-pill" to={`/tableau-de-bord-rf/${id1}/${id2}`} >S’entraîner Gratuitement</Link>
                        <Link className="button rounded-pill" to='/tarif'>Tester En Condition Réelle</Link>
                    </div>
                </div>
            </HeroLayout>
            <Layout2 num={'01'} title={'S\'entraîner à la Compréhension orale'}>
                <div class="pt-2">
                    <p>Choisissez une série et répondez aux questions dans le délai prescrit, chaque question ne comportant qu’une seule réponse correcte parmi les quatre réponses proposées. </p>
                </div>
            </Layout2>
            <Layout2 num={'02'} title={'Consignes générales:'}>
                <div class="pt-3">
                    <ul>
                        <li><span>Cette épreuve est constituée de questions à choix multiples (QCM). Pour chaque question, il y a <b>4 choix</b> de réponse (A, B, C, D) mais <b>une seule réponse est exacte.</b></span></li>
                        <li>Cette épreuve est composée de <b>39 questions</b>.</li>
                        <li>Gérez bien votre temps. cette épreuve dure <b>35 minutes</b>.</li>
                        <li>&nbsp;la question est toujours posée après l’écoute du document, par la même voix qui a lu la consigne.</li>
                        <li>Attention, vous n’entendrez ces documents sonores et les questions qu’une seule fois, alors, écoutez bien !</li>
                        <li>Attention vous ne pouvez pas revenir à une question si vous avez mal répondu.&nbsp;</li>
                        <li>les questions et les situations sont de plus en plus difficiles au fil de l’épreuve.</li>
                    </ul>			
                </div>
            </Layout2>
            
            <TarifFormation />
            <TarifTest />

            <Layout2 num={'03'} title={'Comment réussir la compréhension orale du TCF Canada ? astuces et techniques'}>
                <div class="pt-3">
                    <p>Réussir la compréhension orale du <strong>TCF Canada</strong> peut être un défi, mais il y a certaines stratégies que vous pouvez adopter pour vous préparer de manière efficace. Voici quelques conseils:</p>
                    <p><strong><u>Familiarisez-vous avec le format de l’examen</u></strong>: Comprendre le format et la structure de la section de compréhension orale est essentiel pour réussir. Savoir à quoi s’attendre vous aidera à vous sentir plus à l’aise le jour de l’examen.</p>
                    <p><strong><u>Pratiquez régulièrement</u></strong>: L’écoute active de la langue française est la clé pour améliorer vos compétences en compréhension orale. Vous pouvez écouter la radio française, regarder des films ou des émissions de télévision en français, suivre des podcasts ou des conférences, etc. Faites-le une partie de votre routine quotidienne.</p>
                    <p><strong><u>Utilisez des ressources d’apprentissage</u></strong>: Il existe de nombreux outils et ressources d’apprentissage en ligne qui peuvent vous aider à vous préparer pour la partie de compréhension orale du TCF Canada. Par exemple, le site <Link href="https://formation-tcfcanada.com">www.formation-tcfcanada.com</Link> qui offre des exercices de compréhension orale pour vous entraîner dans les conditions réelles de l’examen.</p>
                    <p><strong><u>Apprenez à prendre des notes</u></strong>: Pendant l’examen, vous serez autorisé à prendre des notes. Cela peut être utile pour garder une trace des informations clés et pour répondre aux questions plus tard. Pratiquez la prise de notes pendant que vous écoutez du français pour améliorer cette compétence.</p>
                    <p><strong><u>Entraînez-vous à comprendre différents accents</u></strong>: Le français parlé au Canada a un accent unique, qui peut être différent de celui que vous avez appris. Essayez de vous familiariser avec l’accent canadien en écoutant des ressources audios spécifiques à ce dialecte.</p>
                    <p><strong><u>Faites des tests de pratique:</u></strong> Faire des tests de pratique vous aidera à vous familiariser avec le type de questions posées et à améliorer votre confiance. Vous pouvez généralement trouver des exemples de tests sur le site officiel du TCF.</p>
                    <p><strong><u>Restez calme et concentré</u></strong>: Lors de l’examen, essayez de rester calme et concentré. Si vous ne comprenez pas une partie de l’audio, ne vous inquiétez pas. Concentrez-vous sur ce que vous pouvez comprendre et faites de votre mieux pour répondre aux questions.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <p><strong>Certaines techniques qui peuvent vous aider à réussir la compréhension orale du TCF Canada :</strong></p>
                    <p>Entraînez-vous à identifier les idées principales et les détails: Lors de l’écoute d’un enregistrement en français, pratiquez l’identification de l’idée principale de ce qui est dit, ainsi que des détails clés qui soutiennent cette idée principale. Cette compétence est cruciale lors de l’examen.</p>
                    <p><strong><u>Prévoyez les types de questions</u></strong>: Les examens de compréhension orale posent généralement certains types de questions, comme demander des détails spécifiques, l’idée principale, l’inférence, ou l’attitude du locuteur. En vous entraînant à répondre à ces types de questions avant l’examen, vous serez mieux préparé.</p>
                    <p><strong><u>Familiarisez-vous avec le vocabulaire et la grammaire:</u></strong> Un vocabulaire et une grammaire solides en français vous aideront à comprendre plus facilement ce qui est dit dans l’enregistrement. N’oubliez pas de vous familiariser avec les expressions idiomatiques et le jargon, qui peuvent souvent être utilisés dans des contextes de conversation réelle.</p>
                    <p><strong><u>Améliorez votre capacité à comprendre l’audio sans images ou contexte visuel</u></strong>: La partie de compréhension orale du TCF Canada ne fournit aucune aide visuelle. Pour vous préparer à cela, pratiquez l’écoute de l’audio en français sans utiliser d’images ou de contexte visuel pour vous aider.</p>
                    <p><strong><u>Soyez prêt à écouter des dialogues et des monologues</u></strong>: La section de compréhension orale du TCF Canada comprend à la fois des dialogues (entre deux personnes) et des monologues (une personne qui parle). Assurez-vous de vous entraîner à écouter les deux types de discours.</p>
                    <p><strong><u>Ne vous attardez pas sur les mots inconnus</u></strong>: Lors de l’examen, si vous entendez un mot que vous ne connaissez pas, ne vous attardez pas dessus. Au lieu de cela, essayez de comprendre le sens général de ce qui est dit en vous basant sur les autres mots et le contexte.</p>
                    <p><strong><u>Pratiquez l’écoute active:</u></strong> C’est une compétence qui peut être améliorée avec le temps. Elle implique de se concentrer pleinement sur ce qui est dit, de réfléchir à l’information, de répondre mentalement aux points clés, et de poser des questions sur ce que vous ne comprenez pas.</p>
                    <p>Ces techniques vous aideront à vous préparer efficacement pour la partie de compréhension orale du TCF Canada. Bonne chance dans vos études et votre préparation pour l’examen !</p>						
                </div>
            </Layout2>
        </main>
    )
}

export default ComprehensionOrale