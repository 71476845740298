import React from 'react'
import Image from "../../image/img2.jpg";
import Image2 from "../../image/img15.webp"
import './style.css'
import Layout3 from '../../layout/Layout3';

const MethExpressionEcrite = () => {
    return (
        <main className='d-flex flex-column gap-4'>
            <section className="section w-100 position-relative mt-0">
                <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                    <div className="box5 text-white d-flex flex-column gap-2 m-5">
                        <h2 className=""><strong>la méthodologie du TCF Canada Expression Écrite</strong></h2>
                        <p className="text-justify fs-5">CL'expression écrite du TCF Canada évalue la capacité des candidats à produire un discours écrit en français.</p>
                    </div>
                </div>
            </section>
            <Layout3 title={'Composantes Du TCF Canada'}>
                <div className='border border-primary shadow rounded p-3'>
                    <p className='fs-5'>Découvrez les 4 épreuves qui composent le <strong>TCF Canada</strong>, le test incontournable pour évaluer vos compétences en français langue générale. Le <strong>TCF CANADA</strong> évalue votre compréhension orale et écrite ainsi que votre expression orale et écrite. Les épreuves de compréhension comportent 39 questions à choix multiple chacune, organisées par niveau de difficulté croissante et chaque question n’a qu’une seule réponse correcte. Quant aux épreuves d’expression orale et écrite, elles se présentent sous forme de 3 tâches chacune. À l’issue du test, vous recevrez une attestation de niveau valable 2 ans. Évaluez dès maintenant votre niveau en français avec le <a href="https://tcf-canada.ca/"><strong>TCF Canada</strong> !</a></p>
                </div>
                <div >
                    <h3 className='blue text-center my-4'><strong>Quatre épreuves obligatoires :</strong></h3>
                    <div className='flex flex-column mx-auto align-items-center '>
                        <div className='methodologie1 p-3 d-flex gap-3 mx-auto flex-column flex-md-row'>
                            <div className='w-100 w-md-50 p-2 bg-primary text-white'>
                                <p className='m-0 fs-5'><strong>Compréhension écrite (39 questions)</strong></p>
                            </div>
                            <div className='w-100 w-md-50 bg-primary'>
                                <p className='w-100 h-100 bg-black p-2 m-0 text-white'>Durée : 60 min |</p>
                            </div>
                        </div>
                        <div className='methodologie1 p-3 d-flex gap-3 mx-auto  flex-column flex-md-row'>
                            <div className='w-100 w-md-50 p-2 bg-primary text-white'>
                                <p className='m-0 fs-5'><strong>Compréhension orale (39 questions)</strong></p>
                            </div>
                            <div className='w-100 w-md-50 bg-primary'>
                                <p className='w-50 h-100 bg-black p-2 m-0 text-white'>Durée : 35 min |</p>
                            </div>
                        </div>
                        <div className='methodologie1 p-3 d-flex gap-3 mx-auto  flex-column flex-md-row'>
                            <div className='w-100 w-md-50 p-2 bg-primary text-white'>
                                <p className='m-0 fs-5'><strong>Expression écrite (3 tâches)</strong></p>
                            </div>
                            <div className='w-100 w-md-50 bg-primary'>
                                <p className='w-100 h-100 bg-black p-2 m-0 text-white'>Durée : 60 min |</p>
                            </div>
                        </div>
                        <div className='methodologie1 p-3 d-flex gap-3 mx-auto flex-column flex-md-row'>
                            <div className='w-100 w-md-50 border p-2 bg-primary text-white'>
                                <p className='m-0 fs-5'><strong>Expression orale (3 tâches)</strong></p>
                            </div>
                            <div className='w-100 w-md-50 bg-primary'>
                                <p className='w-50 h-100 bg-black p-2 m-0 text-white'>Durée : 12 min |</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout3>
            <Layout3 title={'TCF Canada Expression Écrite : Introduction'}>
                <div className='border border-primary shadow rounded p-3 box1'>
                    <p><span >Vous êtes évalué sur vos capacités à :</span></p>
                    <ol>
                        <li><p><span>Communiquer un message de façon claire.</span></p></li>
                        <li><p><span>Donner les informations demandées.</span></p></li>
                        <li><p><span>Décrire, raconter, expliquer.</span></p></li>
                        <li><p><span>Justifier un choix, une position, une décision, etc.&nbsp;</span></p></li>
                        <li><p><span>Enchaîner des idées et faire preuve de cohérence dans votre discours.</span></p></li>
                        <li><p><span>Comparer deux points de vue.&nbsp;</span></p></li>
                        <li><p><span>Exprimer votre avis et l’argumenter.&nbsp;</span></p></li>
                        <li><p><span>Utiliser un vocabulaire et des structures adaptés à la tâche demandée.</span></p></li>
                        <li><p><span >Être capable de faire une synthèse et de reformuler.</span></p></li>
                    </ol>
                    <ul>
                        <li><p><b><span>Durée :1H.</span></b></p></li>
                        <li><p><b><span>Répartition : 03 tâches.</span></b></p></li>
                    </ul>						
             
                </div>
            </Layout3>
            <Layout3 title={'TCF Canada Expression Écrite Tâche 1 : '} >
                <div class="border border-primary shadow rounded p-3 methodologie">
                    <p>Rédaction d’un message pour décrire, raconter et/ou expliquer, adressé à un ou plusieurs destinataires, dont le statut a été précisé dans la consigne. Minimum <strong>60 mots </strong>/maximum <strong>120 mots.</strong></p>
                    <h4 className='blue my-4'><strong>TCF Canada Expression Écrite : La structure du mail Tâche 1 : </strong></h4>
                    <div>
                        <p>Objet : (Mes congés à Marrakech) par exemple.</p>
                        <p><span>1) Salutations : (Coucou Ayoub comment tu vas ?)</span></p>
                        <p><span>2) Objectif général du mail. (je t’écris ce mail pour te dire que je serai à Paris.)</span></p>
                        <p><span>3) Details de la circonstance I s’agit de Qui ? Quoi ? Quand ? où ? Avec qui ?</span></p>
                        <p><span>4) Exprimer les attentes concrètes. (Dépendamment de la consigne du sujet) J’aimerais que tu identifies des endroits chics que je Pourrais visiter.</span></p>
                        <p>6) formules d’Aurevoir (à&nbsp; bientôt, Porte-toi bien, amicalement, cordialement, etc.)</p>
                    </div>						
                </div>
            </Layout3>
            <Layout3 title={'TCF Canada Expression Écrite Tâche 2 :'} >
                <div class="border border-primary shadow rounded p-3 methodologie">
                    <h4 className='blue'>Rédiger avec un Objectif : Conseils pour le TCF Canada Expression Écrite :</h4>
                    <p>Pour commencer, la création d’un article, d’un courrier, ou d’une note vise souvent plusieurs destinataires. Or, il est crucial que ces comptes rendus ou récits soient accompagnés de commentaires, d’opinions ou d’arguments adaptés à l’objectif, qu’il s’agisse de revendiquer, de se réconcilier ou autre. De plus, avec une limite de 120 à 150 mots, la concision est essentielle.</p>
                    <p>L’essentiel ici est de proposer un récit ou un compte rendu avec un but précis, que ce soit pour attirer, plaire, ou réconcilier. Par exemple, si l’exercice exige un mail, sa structure est déterminante. Cependant, si l’on envisage un article de blog, une autre approche s’impose.</p>
                    <p>Envisageons que vous soyez chargé de rédiger un article de blog sur un métier qui vous passionne, avec l’objectif de susciter l’intérêt. D’abord, le titre est le fer de lance de l’attrait d’un article. Ainsi, des titres accrocheurs tels que “03 façons de reconquérir son ex” captent instantanément l’attention du lecteur. Ensuite, le premier paragraphe doit offrir une introduction succincte et séduisante de l’activité ou du sujet. Par la suite, partager votre expérience personnelle apportera une dimension authentique et détaillée à votre texte. En conclusion, ne manquez pas d’ajouter des recommandations, incitant vos lecteurs à explorer davantage le sujet abordé. En somme, respecter ces étapes et s’adapter à la consigne garantira une rédaction efficace&nbsp;et&nbsp;engageante.</p>
                </div>
            </Layout3>
            <Layout3 title={' TCF Canada Expression Écrite Tâche 3 :'} >
                <div class="border border-primary shadow rounded p-3 methodologie">
                    <h4 className='blue'>Rédaction d’un texte pour comparer des opinions</h4>
                    <p>Dans le cadre de cette tâche, vous êtes confronté à deux documents, chacun offrant une perspective différente sur un fait de société. Ainsi, il vous est demandé de concocter un court article, allant d’un minimum de 120 mots à un maximum de 180 mots.</p>
                    <p>D’une part, votre article devra comporter une première section, de 40 à 60 mots, dans laquelle vous synthétiserez les points de vue présentés dans les deux documents. D’autre part, une seconde partie, s’étalant sur 80 à 120 mots, sera dédiée à l’expression de votre propre opinion sur le sujet traité.</p>
                    <h4 className='blue'>Structure de la Tâche 3 du TCF Canada Expression Écrite :</h4>
                    <ul>
                        <li>Titre</li>
                        <li>Synthèse des deux documents</li>
                        <li>Expression de votre opinion</li>
                    </ul>
                    <p>Prenons pour exemple le sujet de l’utilisation d’Internet par les enfants. Pour introduire le débat, vous pourriez écrire :</p>
                    <p>“La question de l’utilisation d’Internet par les enfants attire diverses opinions. D’un côté, certains soutiennent que (Résumé Doc A) l’Internet encourage la paresse et l’inactivité chez les jeunes. D’un autre côté, certains (Résumé Doc B) considèrent cet outil comme une mine d’informations et un catalyseur pour l’imagination.”</p>
                    <p>Quant à votre position, une suggestion serait :</p>
                    <p>“Devant cette situation, on ne peut nier que l’Internet occupe une place centrale dans les sociétés actuelles. Cependant, malgré son omniprésence, il est impératif d’établir un contrôle parental efficace pour surveiller le temps d’utilisation des enfants et garantir la navigation sur des sites adaptés à leur âge.</p>
                </div>
            </Layout3>
            <Layout3 title={'Comment réussir l\'épreuve d\'expression écrite ?'}>
                <div class="border border-primary shadow rounded p-3 methodologie">
                    <h4 className='blue'>Conseils essentiels pour réussir l’exercice de rédaction du TCF Canada :</h4>
                    <p>Premièrement, pour assurer votre succès lors de cette épreuve, il est impératif de suivre attentivement les instructions, d’écrire de manière lisible, de gérer efficacement votre temps et de respecter l’ordre des tâches. Par ailleurs, une lecture approfondie de la demande est essentielle. C’est donc dans l’énoncé que vous trouverez la clé de votre réponse. De plus, la tonalité et le vocabulaire que vous utiliserez varieront en fonction de votre destinataire, que ce soit un ami ou un supérieur hiérarchique.</p>
                    <p>Quant à l’utilisation des pronoms personnels, prenez en compte que l’emploi de « Tu » convient parfaitement pour interagir avec un ami ou un collègue proche. Cependant, l’utilisation de « Vous » est préférable pour communiquer avec un supérieur, un inconnu, un commerçant, etc. Il est donc essentiel de bien identifier le statut de la personne à qui vous vous adressez.</p>
                    <p>Sur le plan de l’objectif de votre rédaction, il est crucial de toujours le garder à l’esprit. Pour la première tâche, par exemple, il s’agit de transmettre un message clairement. Tandis que dans le cas de la tâche 2, l’objectif est de décrire ou d’expliquer. Par conséquent, vous devez, comme illustré précédemment, expliquer pourquoi un moment est plaisant. Toutefois, pour la tâche 3, vous reformulerez les arguments présentés en comparant les points de vue. Ainsi, chaque objectif exige un lexique particulier.</p>
                    <p>Il est également essentiel de ne pas omettre le nombre de mots spécifié par le correcteur. En effet, si le nombre de mots requis n’est pas atteint, des conséquences seront subies.</p>
                    <p>Enfin, exercez-vous régulièrement. En réalité, c’est peut-être le point le plus crucial. D’ailleurs, notre plateforme, Formation-tcfcanada.com, est là pour vous accompagner dans une préparation&nbsp;sereine.</p>
                </div>
                <img src={Image2} className='mx-auto d-block my-4' style={{width: '100%', maxWidth: '1024px' }} />
            </Layout3>
        </main>
    )
}

export default MethExpressionEcrite