import React from "react";
import useWindowSize from '../../Hooks/useWindowSize';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './style.css';
import Tarif from "../Tarif/Tarif";


const TarifTest = () => {
    const {width} = useWindowSize();
    const tarifs = [
        {
            title: "Bronze",
            brand: {
                text:"VERSION 2024",
                color: "#330099"
            },
            price: "10",
            access: "5 JOURS",
            items: [
                "Comprehension Ecrite: <span class='blue'>34</span> tests d'entrainement (+1000 tests)",
                "Comprehension Orale: <span class='blue'>30</span> tests d'entrainement (+1000 Extraits sonores)",
                "Expression Orale: Corrections Tache <span class='blue'>2</span> des actualites.",
                "Expression Orale: Corrections Tache <span class='blue'>3</span> des actualites"
            ],
            link: "/commander"
        },
        {
            title: "Silver",
            brand: {
                text:"VERSION 2024",
                color: "#AAOOFF"
            },
            price: "25",
            access: "1 Mois",
            items: [
                "Comprehension Ecrite: <span class='blue'>34</span> tests d'entrainement (+1000 tests)",
                "Comprehension Orale: <span class='blue'>30</span> tests d'entrainement (+1000 Extraits sonores)",
                "Expression Orale: Corrections Tache <span class='blue'>2</span> des actualites.",
                "Expression Orale: Corrections Tache <span class='blue'>3</span> des actualites"
            ],
            link: "/commander"
        },
        {
            title: "Gold",
            brand: {
                text:"VERSION 2024",
                color: "#33CC55"
            },
            price: "45",
            access: "2 Mois",
            items: [
                "Comprehension Ecrite: <span class='blue'>34</span> tests d'entrainement (+1000 tests)",
                "Comprehension Orale: <span class='blue'>30</span> tests d'entrainement (+1000 Extraits sonores)",
                "Expression Orale: Corrections Tache <span class='blue'>2</span> des actualites.",
                "Expression Orale: Corrections Tache <span class='blue'>3</span> des actualites"
            ],
            link: "/commander"
        },
        
    ]
    return (
        <section className="section4">
            <div className="d-flex flex-column gap-2 align-items-center ps-3 pe-3">
                <h3 className="text-center">NOS TESTS SONT TIRES DE L'EXAMEN REEL DU <span className="blue">TCF</span></h3>
                <p>Faites vos choix, enregistrez-vous, et commencez aujourd'hui</p>
                <Splide
                    style={{width: width > 1100 ? '70%' : '100%'}}
                    options={{
                        perPage:   width < 750? 1 : 3 ,
                        arrows: width < 750,
                        pagination: true,
                        drag: "free",
                        gap: "3rem",
                    }}
                    data-aos="zoom-in-up"
                >
                {tarifs.map((tarif) => (
                    <SplideSlide>
                        <Tarif {...tarif} />
                    </SplideSlide>
                ))

                }
                </Splide>
            </div>
        </section>
    )
}






export default TarifTest