import React from "react";
import './style.css'
import FormationSection1 from "../../components/FormationSection1/FormationSection1";
import FormationSection2 from "../../components/FormationSection2/FormationSection2";
import FormationSection3 from "../../components/FormationSection3/FormationSection3";
import TarifFormation from "../../components/TarifFormation/TarifFormation";
import TarifTest from "../../components/TarifTest/TarifTest";

const Formation = () => {
    return (
        <main>
            <section className="hero3 d-flex flex-column justify-content-center align-items-center mt-0 text-white">
                <h1>Formations</h1>
                <p className="fs-4">C’est notre promesse de vous accompagner vers la réussite de votre examen</p>
            </section>
            <FormationSection1 />
            <FormationSection2 />
            <FormationSection3 />
            <TarifFormation />
            <TarifTest />
        </main>
    )
}

export default Formation