import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import Image from "../../image/img2.jpg";
import Image2 from "../../image/img16.webp";

const PourquoiTCF = () => {
    return (
        <main style={{lineHeight: '2rem'}}>
            <section className="section w-100 position-relative mt-0" style={{height: '30rem'}}>
                <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3"> 
                    <h2 className="text-center mb-2 text-white"><strong>Pourquoi choisir notre plate-forme ? </strong></h2>
                </div>
            </section>
            <section className='px-5'>
                <div className='container shadow p-5 connaissance'>
                    
                    <h3 className='blue my-5'><strong>Introduction :</strong></h3>
                    <p>Lorsqu’il s’agit de préparer avec succès le Test de Connaissance du Français (TCF) Canada, choisir la bonne ressource de préparation est essentiel. www.formation-tcfcanada.com se positionne comme votre meilleur choix pour vous aider à obtenir des résultats exceptionnels. Voici cinq raisons convaincantes pour lesquelles vous devriez opter pour <Link to=''>www.formation-tcfcanada.com</Link> pour votre préparation au TCF Canada.</p>
                    <h3 className='blue my-5'><strong>Contenu de qualité et exhaustif :</strong></h3>
                    <p> <Link to=''> www.formation-tcfcanada.com</Link> propose un contenu de préparation au TCF Canada de haute qualité et exhaustif. Vous bénéficiez d’une vaste gamme de ressources telles que des leçons, des exercices, des tests pratiques et des simulations d’examens. Chaque aspect du TCF Canada est couvert de manière approfondie, vous permettant ainsi de vous familiariser avec les différents domaines évalués et de renforcer vos compétences linguistiques.</p>
                    <h3 className='blue my-5'><strong>Approche pédagogique personnalisée :</strong></h3>
                    <p> <Link to=''> www.formation-tcfcanada.com</Link> reconnaît que chaque candidat a des besoins et des objectifs uniques. Leur approche pédagogique est axée sur la personnalisation de votre programme de préparation. En tenant compte de vos forces et de vos faiblesses, Notre plate-forme vous propose un plan d’étude adapté à vos besoins spécifiques. Cela garantit une préparation ciblée et efficace, maximisant ainsi vos chances de réussite au TCF Canada.</p>
                    <h3 className='blue my-5'><strong>Accès à des ressources interactives :</strong></h3>
                    <p>Une des forces de <Link to=''>www.formation-tcfcanada.com</Link> réside dans ses ressources interactives. Vous pouvez accéder à des vidéos, des enregistrements audios et des exercices interactifs qui renforcent votre compréhension orale et écrite. Ces ressources interactives vous permettent de pratiquer activement vos compétences linguistiques, d’améliorer votre prononciation et de vous familiariser avec le format de l’examen, vous donnant ainsi une longueur d’avance lors du jour de l’examen.</p>
                    <h3 className='blue my-5'><strong>Évaluation et rétroaction précises :</strong></h3>
                    <p><Link to=''>www.formation-tcfcanada.com</Link> offre un suivi précis de vos progrès grâce à des évaluations régulières. Vous pouvez passer des tests pratiques et des examens blancs qui simulent les conditions réelles de l’examen du TCF Canada. Ces évaluations vous aident à identifier vos points forts et vos points faibles, vous permettant de concentrer vos efforts là où cela est nécessaire. De plus, vous recevrez une rétroaction détaillée et des conseils personnalisés pour améliorer vos performances.</p>
                    <h3 className='blue my-5'><strong>Assistance et support dédiés :</strong></h3>
                    <p>En choisissant <Link to=''>www.formation-tcfcanada.com</Link> vous bénéficiez d’une assistance et d’un soutien dédiés tout au long de votre parcours de préparation. Leur équipe est disponible pour répondre à vos questions, vous fournir des conseils pratiques et vous guider tout au long du processus. Vous ne serez jamais seul dans votre préparation au TCF Canada, car notre plate-forme sera là pour vous soutenir et vous encourager à chaque étape.</p>
                    <div className='d-flex justify-content-center my-5'>
                        <img src={Image2} className='d-inline-block mx-auto' style={{width: '100%', maxWidth: '1000px'}} />
                    </div>
                </div>
            </section>
        </main>
    )
}

export default PourquoiTCF