import React from "react";
import { Link } from "react-router-dom";
import './style.css';


const DisplayPrepare = ({modules, nAccessible, link}) => {
    
    return (
        
        <div className="d-flex flex-wrap justify-content-center flex-column flex-sm-row align-items-center">
           
            {modules.map((module, index) => (
                <div key={index} className="d-flex text-center quizbox justify-content-center fs-6">
                    {
                        <div className="bg-primary text-white py-3 px-5 rounded-pill">
                            <Link to={`/${link}/${module.id}`} className="text-white">
                                <span className="">{module.intitule}</span>
                            </Link>
                        </div>
                    }
               </div>
            ))}
        </div>
    )
}

export default DisplayPrepare