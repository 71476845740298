import React from "react";
import { FaBook } from 'react-icons/fa';
import useWindowSize from '../../Hooks/useWindowSize';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import './style.css';
import Tarif from "../Tarif/Tarif";

const TarifFormation = () => {
    const {width} = useWindowSize();
    const tarifs = [
        {
            title: "Essentiel",
            price: "70",
            temp: "15 JOURS",
            items: [
                "<span class='blue'>5</span> seances (Expression orale, Expression ecrite)",
                "Duree de la Formation: <span class='blue'>15</span> jours (Extensive)",
                "Jours de la Formation: Samedi, Dimanche",
                "Traitement des sujets recents (Niveau Avance : <strong>C1/C2</strong>)",
                "Cours individuels personnalises (a distance)",
                "Cours theorique, exercices et correction approfondie pour ameiliorer vos redactions.",
                ""
            ],
            link: "/commander"
        },
        {
            title: "Standard",
            price: "90",
            temp: "20 JOURS",
            items: [
                "<span class='blue'>8</span> seances (Expression orale, Expression ecrite)",
                "Duree de la Formation: <span class='blue'>20</span> jours (Extensive)",
                "Jours de la Formation: Vendredi, Samedi, Dimanche",
                "Traitement des sujets recents (Niveau Avance : <strong>C1/C2</strong>)",
                "Cours individuels personnalises (a distance)",
                "Cours theorique, exercices et correction approfondie pour ameiliorer vos redactions.",
                ""
            ],
            link: "/commander"
        },
        {
            title: "Premium",
            brand: {
                text:"LE PLUS POPULAIR",
                color: "#333333"
            },
            price: "120",
            temp: "1 MOIS",
            items: [
                "<span class='blue'>8</span> seances (Expression orale, Expression ecrite)",
                "Duree de la Formation: <span class='blue'>30</span> jours (Extensive)",
                "Jours de la Formation: du Lundi au Vendredi",
                "Traitement des sujets recents (Niveau Avance : <strong>C1/C2</strong>)",
                "Cours individuels personnalises (a distance)",
                "Cours theorique, exercices et correction approfondie pour ameiliorer vos redactions.",
                "Plate-forme de redaction et de correction",
                "Comprehension Ecrite: <span class='blue'>34</span> tests d'entrainement (+1000 tests)",
                "Comprehension Orale: <span class='blue'>30</span> tests d'entrainement (+1000 Extraits sonores)",
                "Expression Orale: Corrections Tache <span class='blue'>2</span> des actualites.",
                "Expression Orale: Corrections Tache <span class='blue'>3</span> des actualites"
            ],
            link: "/commander"
        },
        {
            title: "PlATINUIM",
            price: "160",
            temp: "1 MOIS",
            items: [
                "<span class='blue'>10</span> seances (Expression orale, Expression ecrite)",
                "Duree de la Formation: <span class='blue'>60</span> jours (Extensive)",
                "Jours de la Formation: du Lundi au Vendredi",
                "Traitement des sujets recents (Niveau Avance : <strong>C1/C2</strong>)",
                "Cours individuels personnalises (a distance)",
                "Cours theorique, exercices et correction approfondie pour ameiliorer vos redactions.",
                "Plate-forme de redaction et de correction",
                "Comprehension Ecrite: <span class='blue'>34</span> tests d'entrainement (+1000 tests)",
                "Comprehension Orale: <span class='blue'>30</span> tests d'entrainement (+1000 Extraits sonores)",
                "Expression Orale: Corrections Tache <span class='blue'>2</span> des actualites.",
                "Expression Orale: Corrections Tache <span class='blue'>3</span> des actualites"
            ],
            link: "/commander"
        }
    ]
    return (
        <section className="section4">
            <div className="d-flex flex-column align-items-center head">
                <h2>TARIF</h2>
                <div>
                    <FaBook/>
                </div>
            </div>
            <div className="d-flex flex-column gap-2 align-items-center ps-3 pe-3">
                <h3 className="text-center">NOS FORFAITS <span className="blue">POUR FORMATION</span> CHEZ NOUS</h3>
                <p>Faites vos choix, enregistrez-vous, et commencez aujourd'hui</p>
                <Splide
                style={{width: width > 1300 ? '70%' : '100%'}}
                options={{
                    perPage:   width < 800? 1 : 3 ,
                    arrows: true,
                    pagination: true,
                    drag: "free",
                    gap: "3rem",
                    autoplay: true, 
                    interval: 5000,
                    rewind: true
                }}
                data-aos="zoom-in-up"
            >
            {tarifs.map((tarif) => (
                <SplideSlide>
                    <Tarif {...tarif} />
                </SplideSlide>
            ))

            }
            </Splide>
            </div>
        </section>
    )
}






export default TarifFormation