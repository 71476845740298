import React from "react";
import './style.css'
import HeroLayout from "../../layout/HeroLayout";
import { Link } from "react-router-dom";

const ExpressionEcriteSection1 = () => {
    return (
        <HeroLayout>
            <div className="d-flex flex-column gap-5 text-white align-items-center">
                <h1 className="fs-1">Expression Écrite</h1>
                <p className="fs-3" style={{maxWidth: '75rem', width: '90%'}}>Afin de vous préparer au mieux au TCF et booster votre score, nous mettons à votre disposition un test qui réunit les mêmes conditions que lors d’une session officielle</p>
                <div className="d-flex gap-4 flex-md-row flex-column">
                    <Link className="button rounded-pill" to='/expression-ecrite-sujets-dactualites'>Sujets d'actualites</Link>
                    <Link className="button rounded-pill" to='/#pispis'>S'entrainer</Link>
                </div>
            </div>
        </HeroLayout>
    )
}

export default ExpressionEcriteSection1