import React from "react";
import TarifFormation from "../../components/TarifFormation/TarifFormation";
import TarifTest from "../../components/TarifTest/TarifTest";
import Layout2 from "../../layout/Layout2";
import './style.css'
import HeroLayout from "../../layout/HeroLayout";
import { Link } from "react-router-dom";


const ComprehensionEcrite = () => {

    const id1 = sessionStorage.getItem('idComprehensionEcrite')
    const id2 = sessionStorage.getItem('idComprehensionOral')

    return (
        <main>
            <HeroLayout>
                <div className="d-flex flex-column gap-5 text-white align-items-center">
                    <h1 className="fs-1">Se préparer au TCF (Compréhension Écrite)</h1>
                    <p className="fs-3" style={{maxWidth: '75rem', width: '90%'}}>Afin de vous préparer au mieux au TCF et booster votre score, nous mettons à votre disposition des tests qui réunissent les mêmes conditions que lors d’une session officielle</p>
                    <div className="d-flex gap-4 flex-md-row flex-column">
                        <Link className="button rounded-pill" to={`/tableau-de-bord-rf/${id1}/${id2}`}>S’entraîner Gratuitement</Link>
                        <Link className="button rounded-pill" to='/tarif'>Tester En Condition Réelle</Link>
                    </div>
                </div>
            </HeroLayout>
            <Layout2 num={'01'} title={'S\'entraîner à la Compréhension écrite'}>
                <div class="pt-2">
                    <p>Choisissez une série et répondez aux questions dans le délai prescrit, chaque question ne comporte qu’une seule réponse correcte parmi les quatre réponses proposées. </p>
                </div>
            </Layout2>
            <Layout2 num={'02'} title={'Consignes générales:'}>
                <div class="pt-3">
                    <ul>
                        <li>Cette épreuve est constituée de questions à choix multiples (QCM). Pour chaque question, il y a 4 choix de réponse (A, B, C, D) mais une seule réponse est exacte.</li>
                        <li>Cette épreuve est composée de <b>39 questions.</b></li>
                        <li>Gérez bien votre temps. Cette partie dure <b>60 minutes.</b></li>
                        <li>Essayez de lire la question avant de lire le texte.&nbsp;</li>
                        <li>Vous pouvez revenir en arrière si vous n’avez pas répondu ou mal répondu à une question.</li>
                        <li>Les questions se présentent selon un principe de difficulté progressive.</li>
                    </ul>						
                </div>
            </Layout2>
            <Layout2 num={'03'} title={'Stratégies pour réussir la compréhension écrite (TCF CANADA):'}>
                <div class="pt-2 d-flex flex-column gap-2">
                    <p><b>Conseil n°1 - Commencez par lire la question</b></p>
                    <p>Il est souvent très utile de commencer par lire la question avant de lire le document écrit lié à la question. En&nbsp;faisant cela, vous saurez directement quelle information vous recherchez et cela vous facilitera la tâche au moment de chercher la réponse.</p>
                    <p>De plus, lire la question vous fera gagner du temps pour les textes de niveau avancé qui sont souvent plus longs et complexes. Savoir quel type d’information vous cherchez (position de l’auteur, élément factuel, information générale ou spécifique, etc.) vous permettra de rendre plus efficace votre lecture du document.</p>
                    <p><b>Conseil n°2 – Lisez bien les réponses proposées</b></p>
                    <p>Après avoir lu la question puis le texte support, lisez attentivement les propositions de réponses. En effet, il est possible que plusieurs réponses semblent être possibles. Il faut donc faire attention au contenu exact des choix pour, par exemple, repérer si un pronom est erroné (comme l’utilisation du masculin au lieu du féminin) ou encore si un temps ne correspond pas à la temporalité du texte (une proposition de réponse utilisant le futur pour une action ou un fait qui s’est déjà produit).</p>
                    <p><b>Conseil n°3 – Repérez la logique du texte</b></p>
                    <p>Pour les textes plus longs et complexes, ne vous laissez pas décourager&nbsp;! Faites attention aux transitions et aux connecteurs logiques pour comprendre l’articulation du texte. Cela vous permettra de repérer l’intention de l’auteur et la cohérence interne au texte. Grâce à cet effort, vous pourrez éliminer des propositions de réponse qui ne conviennent pas.</p>
                    <p>Pour ce point, vous pouvez consulter la fiche «&nbsp;<a href="https://prepmyfuture.com/platform/product_tabs/lesson_tab/1689">Mots de liaison</a>&nbsp;» qui vous permettra de réviser les connecteurs logiques les plus fréquemment utilisés.</p>
                    <p><b>Conseil n°4 – Aidez-vous du contexte et essayez</b></p>
                    <p>Plus les textes sont complexes, plus il est probable que des mots de vocabulaire ou des tournures de phrases vous soient étrangères. Même si vous ne comprenez pas tous les mots, appuyez-vous sur l’idée générale pour pouvoir en déduire un maximum d’informations. Cela pourra peut-être vous aider à éliminer une partie des propositions de réponse et ainsi augmenter vos chances de répondre correctement.</p>
                    <p>Enfin, si vous gagnez des points en répondant correctement à une question,&nbsp;<b>vous n’en perdez pas</b>&nbsp;quand vous donnez la mauvaise réponse ou que vous faites une erreur. Vous avez donc tout intérêt à essayer de répondre à toutes les questions, cela ne peut pas faire de mal à votre score.</p>						</div>
            </Layout2>
            
            <TarifFormation />
            <TarifTest />

            <Layout2 num={'04'} title={'Comment réussir la compréhension écrite du TCF Canada ? astuces et techniques ? '}>
                <div class="pt-3">
                    <p>La réussite de la section de compréhension écrite du TCF Canada nécessite une pratique régulière et une bonne connaissance du français écrit. Voici quelques astuces et techniques qui pourraient vous aider :</p>
                    <p><strong><u>Familiarisez-vous avec le format de l’examen</u></strong>: Comme pour la compréhension orale, il est essentiel de connaître la structure de l’examen de compréhension écrite. Cela comprend le nombre de questions, le type de textes que vous serez amené à lire, et le temps imparti.</p>
                    <p><strong><u>Pratiquez la lecture en français:</u></strong> Lisez régulièrement des textes en français pour vous habituer à la structure des phrases, au vocabulaire et à l’orthographe. Essayez de diversifier vos sources pour couvrir un large éventail de sujets et de styles, tels que les journaux, les romans, les magazines et les articles en ligne.</p>
                    <p><strong><u>Améliorez votre vocabulaire</u></strong>: Connaître un grand nombre de mots et d’expressions vous aidera non seulement à comprendre les textes, mais aussi à répondre plus rapidement aux questions. Essayez d’apprendre de nouveaux mots chaque jour et utilisez-les dans des phrases pour mieux les mémoriser.</p>
                    <p><strong><u>Apprenez à repérer les informations clés</u></strong>: Lorsque vous lisez un texte, entraînez-vous à repérer les informations importantes qui vous aideront à répondre aux questions. Cela peut inclure les idées principales, les détails spécifiques et l’attitude ou l’opinion de l’auteur.</p>
                    <p><strong><u>Pratiquez des exercices de compréhension écrite</u></strong>: Il existe de nombreux exercices de compréhension écrite disponibles en ligne ou dans les manuels de préparation aux examens. Ces exercices vous aideront à vous habituer au type de questions qui seront posées lors de l’examen.</p>
                    <p><strong><u>Gérez votre temps efficacement:</u></strong> Lors de l’examen, vous devez lire les textes et répondre aux questions dans un temps limité. Pratiquez la gestion du temps lors de vos révisions pour vous assurer que vous pouvez terminer tous les éléments de l’examen dans le temps imparti.</p>
                    <p><strong><u>Revoyez la grammaire française:</u></strong> Une bonne connaissance de la grammaire française vous aidera à comprendre les textes plus facilement. Revoyez les règles grammaticales, les temps verbaux et les structures de phrases avant l’examen.</p>
                    <p><strong><u>Restez calme et concentré</u></strong>: Lors de l’examen, essayez de rester calme et concentré. Si vous ne comprenez pas une partie du texte, ne vous inquiétez pas. Concentrez-vous sur ce que vous pouvez comprendre et faites de votre mieux pour répondre aux questions.</p>
                    <p>&nbsp;</p>
                    <p>Rappelez-vous, la clé pour réussir la section de compréhension écrite du TCF Canada est la préparation et la pratique régulières. Bonne chance !</p>						</div>
            </Layout2>
        </main>
    )
}

export default ComprehensionEcrite