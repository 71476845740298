import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{height: '300px'}}>
            <Spinner animation='border' variant='primary' style={{width: '100px', height: '100px', borderWidth: '10px'}} />
        </div>
    )
}

export default Loading