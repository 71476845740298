import React from 'react'
import './style.css'
import Image from "../../image/img2.jpg";
import { FaBook, FaRegEdit } from 'react-icons/fa';

const SujetExpressionEcrite = () => {
    return (
        <main>
            <section className="section w-100 position-relative mt-0" style={{height: '25rem'}}>
                <img src={Image} alt="background" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                    <div className="box5 text-white d-flex flex-column gap-5 m-5 align-items-center">
                        <h2 className="text-center mb-2"><strong>Expression Écrite Avril 2024</strong></h2>
                        <p className="text-center fs-5" style={{width: '100%', maxWidth: '700px'}}>écouvrez Les nouveaux sujets de l’expression écrite qui se répètent. Pratiquez sur ces thèmes afin d'obtenir de bonnes notes.</p>
                    </div>
                </div>
            </section>
            <section className='container m-3 mx-auto mt-5'>
                <div className='shadow mt-5 p-5 d-flex flex-column gap-3'>
                    <p className='text-start blue'><strong>Sujets d'actualités Avril 2024</strong></p>
                    <div className='attention p-4'>
                        <h3><strong>Attention !</strong></h3>
                        <p className='fs-5 text-start'>Ces contenus sont basés sur des sujets réels ! Ils ne sont pas les originaux. </p>
                    </div>
                    <div className='d-flex gap-5 fs-3 mt-3'>
                        <button className='flex-grow-1 text-white p-3 bg-button1 rounded-3'>Correction<FaRegEdit className='text-end' /></button>
                        <button className='flex-grow-1 text-white p-3 bg-button2 rounded-3'>Formation<FaBook /></button>
                    </div>
                </div>
                <div className='shadow p-5'>
                        <div className='d-flex'>
                            <h3 className='blue fs-2'>Combinaision 5</h3>
                            <hr className='flex-grow-1' />
                        </div>
                        <div className='mt-5'>
                            <span className='blue p-3 fs-3 border border-primary rounded-2'><strong>Tâche 1</strong></span>
                            <div className='question border border-primary mt-4'>
                                <div className='gradient-sujet p-5'>
                                    <p>Votre avez trouvé un nouveau travail. Vous écrivez à votre amie francophone pour lui annoncer la bonne nouvelle. Vous décrivez votre poste, vos collègues et votre lieu de travail</p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <span className='blue p-3 fs-3 border border-primary rounded-2'><strong>Tâche 2</strong></span>
                            <div className='question border border-primary mt-4'>
                                <div className='gradient-sujet p-5'>
                                    <p>une étudiante qui a 19 ans veut aller à l’étranger pour les études. Elle demande aux internautes du forum de partager leurs expériences et vous avez fait un an à l’étranger.<span className='blue'>Parlez lui de votre expérience (décrire le séjour à l’étranger, les activités). </span></p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <span className='blue p-3 fs-3 border border-primary rounded-2'><strong>Tâche 3</strong></span>
                            <div className='question border border-primary mt-4'>
                                <div className='gradient-sujet p-5'>
                                    <h3 className='blue text-center'><strong>l'aide aux personnes pauvres</strong></h3>
                                    <h3 className='blue mt-4'><strong><u>Document 1:</u></strong></h3>
                                    <p className='mt-3 border border-primary p-5'><strong><u>Document 1 (Marie 58 ans):</u></strong> J’ai pris l’habitude, depuis des années maintenant, de donner un peu de mon temps et de mon argent afin d’aider les personnes vivant dans la précarité extrême; surtout ceux qui vivent sans abri en période hivernale. Pour moi, c’est un devoir d’aider et de contribuer afin que ces personnes puissent vivre le plus normalement possible. En assistant des pauvres ou des associations, on se sent utile au sein d’une société qui devient de plus en plus impitoyable.</p>
                                    <h3 className='blue mt-4'><strong><u>Document 1:</u></strong></h3>
                                    <p className='mt-3 border border-primary p-5'><strong><u>Document 1 (Marie 58 ans):</u></strong> J’ai pris l’habitude, depuis des années maintenant, de donner un peu de mon temps et de mon argent afin d’aider les personnes vivant dans la précarité extrême; surtout ceux qui vivent sans abri en période hivernale. Pour moi, c’est un devoir d’aider et de contribuer afin que ces personnes puissent vivre le plus normalement possible. En assistant des pauvres ou des associations, on se sent utile au sein d’une société qui devient de plus en plus impitoyable.</p>
                                </div>
                            </div>
                        </div>
                        
                </div>
                
            </section>
            
        </main>
    )
}

export default SujetExpressionEcrite