import React from 'react'
import './style.css'
import Image from "../../image/img2.jpg";
import { Link } from 'react-router-dom';

const ExpressionOraleSujet = () => {
    const sujets = [
        {date: "Mars 2024", link: ""},
        {date: "Fevrier 2024", link: ""},
        {date: "Javier 2024", link: ""},
        {date: "Decembre 2023", link: ""},
        {date: "November 2023", link: ""},
        {date: "Octobre 2023", link: ""},
        {date: "Septembre 2023",link: ""},
        {date: "Aout 2023", link: ""},
        {date: "Juillet 2023",link: ""},
        {date: "Juin 2023", link: ""},
        {date: "Mai 2023", link: ""},
        {date: "Avril 2023", link: ""},
        {date: "Mars 2023", link: ""},
        {date: "Fevrier 2023", link: ""},
        {date: "Janvier 2023", link: ""},
        {date: "Decembre 2022", link: ""},
        {date: "November 2022", link: ""},
        {date: "Octobre 2022", link: ""},
        {date: "Septembre 2022",link: ""},
        {date: "Aout 2022", link: ""},
    ]

    return (
        <main>
            <section className="section w-100 position-relative mt-0" style={{height: '25rem'}}>
                <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                    <div className="box5 text-white d-flex flex-column gap-5 m-5 align-items-center">
                        <h2 className="text-center mb-2"><strong>Expression Oral (sujets d'actualités) </strong></h2>
                        <p className="text-center fs-5" style={{width: '100%', maxWidth: '700px'}}>Découvrez Les nouveaux sujets de l’expression orale qui se répètent. Pratiquez sur ces thèmes afin d'obtenir de bonnes notes.</p>
                    </div>
                </div>
            </section>
            <section className='mb-5'>
                <div className='contenu-actualite d-flex flex-column gap-3 mx-auto p-3'>
                    {sujets.map((sujet, ind) => (
                        <Link to={sujet.link}>
                            <div key={ind} className='py-2 px-5 bg-primary text-white d-flex align-items-center rounded-4'>
                                <div className='sujet-circle border border-white rounded-circle self-align-start'></div>
                                <p className='text-center fs-4 mx-auto'>les sujets d'actualités {sujet.date}</p>
                            </div>
                        </Link>
                    ) )}
                </div>
            </section>
        </main>
    )
}

export default ExpressionOraleSujet