import React from "react";
import { FaBook } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { FaUserCheck, FaGraduationCap, FaCheckCircle, FaDollarSign } from 'react-icons/fa';

import './style.css';

const HomeSection3 = () => {
    return (
        <section className="section3" data-aos="zoom-out">
            <div className="d-flex flex-column align-items-center head">
                <h2>POURQUOI CHOISIR NOTRE PLATE-FORME</h2>
                <div>
                    <FaBook/>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-between gap-4 p-2 flex-wrap flex-column flex-md-row">
                <div className="item rounded-5 d-flex flex-column  p-4 align-items-center text-center text-white">
                    <FaUserCheck className="font-icon" />
                    <span className="fs-4">NOTRE PÉDAGOGIE</span>
                    <span className="fs-6">Nous mettons à votre disposition des simulations qui réunissent les mêmes conditions que lors d'un examen officiel. </span>
                </div>
                <div className="item rounded-5 d-flex flex-column  p-4 align-items-center text-center text-white">
                    <FaGraduationCap className="font-icon" />
                    <span className="fs-4">COURS SUR MESURE </span>
                    <span className="fs-6">Des cours élaborés sur mesure pour atteindre le niveau le plus élevé de votre tcf</span>
                </div>
                <div className="item rounded-5 d-flex flex-column  p-4 align-items-center text-center text-white">
                    <FaCheckCircle className="font-icon" />
                    <span className="fs-4">EXPERT EN FORMATION </span>
                    <span className="fs-6">Nos évaluateurs forment plus de 550 candidats par an) </span>
                </div>
                <div className="item rounded-5 d-flex flex-column  p-4 align-items-center text-center text-white">
                    <FaDollarSign className="font-icon" />
                    <span className="fs-4">TARIFICATION</span>
                    <span className="fs-6">Nous vous formons aux meilleurs prix (nos tarifs sont compétitifs) </span>
                </div>
            </div>
        </section>
    )
}






export default HomeSection3