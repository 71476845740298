import React from 'react'
import Image from '../../image/img14.webp'
import './style.css'
import { FaArrowRight, FaCheck, FaCheckCircle, FaCogs, FaGraduationCap, FaWalking } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ExpressionEcritePlateForme = () => {
    return (
        <main>
            <div className='plateforme d-flex flex-column-reverse flex-md-row gap-5 bg-primary p-5 align-items-center'>
                <div className='plateformebox1 w-100 w-md-50 text-white d-flex flex-column align-items-center'>
                    <h2 className='fs-1'>Des rédactions exceptionnelles qui vont au-delà de toutes vos attentes !</h2>
                    <p>Sublimez vos rédactions en faisant appel à nos Rédacteurs et correcteurs professionnels pour la correction et l'amélioration stylistique de vos textes.</p>
                    <div className='text-dark bg-white p-2 d-flex gap-3 align-items-center me-auto'>
                        <span className='fs-5'>Placez votre commande</span>
                        <FaArrowRight className='fs-5' />
                    </div>
                </div>
                <img src={Image} alt='profile' className='w-100 w-md-50 img' />
            </div>
            <div className='plateform2 d-flex flex-column flex-md-row justify-content-between text-white gap-5 p-5'>
                <div className='bg-primary flex-grow-1 px-5 py-2 d-flex align-items-center justify-content-center fs-4'>
                    <span>Garanti zero faute </span>
                    <FaCheck />
                </div>
                <div className='bg-primary flex-grow-1 px-5 py-2 d-flex align-items-center justify-content-center fs-4'>
                    <span>Redacteurs professionnels</span>
                    <FaGraduationCap />
                </div>
                <div className='bg-primary flex-grow-1 px-5 py-2 d-flex align-items-center justify-content-center fs-4'>
                    <span>Livraison rapide</span>
                    <FaWalking />
                </div>
            </div>
            <div className='plateforme3'>
                <h2 className='blue text-center mb-3 p-3'><strong>Faites rediger et corriger  vos textes par nos redacteurs</strong></h2>
                <p className='fs-4'>Chacune de vos rédactions fait l'objet d'un traitement particulier. Nos conseillères veillent au bon déroulement de toutes vos commandes, du choix des correcteurs jusqu'à la livraison finale des corrections.</p>
                <div className='d-flex flex-column flex-md-row gap-5'>
                    <div className='p-5 w-100 w-md-50 d-flex flex-column gap-4 align-items-center shadow'>
                        <FaCheckCircle className='fs-1 blue' />
                        <h2 className='blue text-center mb-3'><strong>Redaction</strong></h2>
                        <p className='fs-5 text-start'><strong>Les rédacteurs identifieront et corrigeront toutes les fautes d'orthographe, de grammaire, de conjugaison et de vocabulaire.</strong></p>
                        <Link to="" className='button rounded'>Acceder</Link>
                    </div>
                    <div className='p-5 w-100 w-md-50 d-flex flex-column gap-4 align-items-center shadow'>
                        <FaCogs className='fs-1 blue' />
                        <h2 className='blue text-center mb-3'><strong>Redaction</strong></h2>
                        <p className='fs-5 text-start'><strong>Les rédacteurs identifieront et corrigeront toutes les fautes d'orthographe, de grammaire, de conjugaison et de vocabulaire.</strong></p>
                        <Link to="" className='button rounded'>Acceder</Link>
                    </div>
                </div>
            </div>
            <div className='plateforme3'>
                <div className=' d-flex gap-5 align-items-center my-5'>
                    <hr className='flex-grow-1' />
                    <span className='fs-1 blue'><strong>Tarif</strong></span>
                    <hr className='flex-grow-1' />
                </div>
                <div className="plateformebox3 rounded-5 shadow text-center overflow-hidden border option d-flex flex-column">
                    <div className="title d-flex flex-column justify-content-center align-items-center position-relative bgblue">
                        <h3 className="fs-2 font-weight-bold">Expression écrite </h3>
                        <br />
                        <span>Service de rédaction </span>
                    </div>
                    <div className="body d-flex flex-column align-items-center justify-content-between ">
                        <div>
                            <div className="mb-3 mt-3">
                                <h3 className="font-price">$<span>80</span></h3>
                            </div>
                            <ul>
                                <li className="pt-4 pb-4 fs-5"><FaCheckCircle className='text-success me-3' /> Livraison 48H</li>
                                <li className="pt-4 pb-4 fs-5"><FaCheckCircle className='text-success me-3' /> Niveau: C1-C2</li>
                                <li className="pt-4 pb-4 fs-5"><FaCheckCircle className='text-success me-3' /> Protection anti-plagiat</li> 
                            </ul>
                        </div>
                        <Link to="" className="button rounded-pill mt-3 mb-3">S'ABONNER</Link>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ExpressionEcritePlateForme