import React, { memo } from "react";
import "./style.css";

const QuestionLevel = memo(({handleQuestion, actualQuestion, reponse, handleReviser, result, questions}) => {
    //console.log(questions)
    
    return (
        <div>
            <div className="questionlevel d-flex flex-wrap rounded-3">
                {reponse.map((val, index) => {
                    if(!result){
                        if(index+1 === actualQuestion && val === "") return (
                            <div className="number rounded-circle bg-white border border-black" onClick={() => handleQuestion(index+1)}>
                                <span className="fs-5">{index+1}</span>
                            </div>
                        )
                        else if(val === -1 && index+1 === actualQuestion) return (
                            <div className="number rounded-circle bg-yellow border border-black" onClick={() => handleQuestion(index+1)}>
                                <span className="fs-5">{index+1}</span>
                            </div>
                        )
                        else if(val === -1) return (
                            <div className="number rounded-circle bg-yellow" onClick={() => handleQuestion(index+1)}>
                                <span className="fs-5">{index+1}</span>
                            </div>
                        )
                        else if(val) return (
                            <div className="number rounded-circle bg-dark text-white" onClick={() => handleQuestion(index+1)}>
                                <span className="fs-5">{index+1}</span>
                            </div>
                        )
                        else return(
                            <div className="number rounded-circle bg-white" onClick={() => handleQuestion(index+1)}>
                                <span className="fs-5">{index+1}</span>
                            </div>
                        )
                    }else{
                        if(questions[index].reponses.find((response) => response.id === val).veracite) return(
                            <div className="number rounded-circle bg-green" onClick={() => handleQuestion(index+1)}>
                                <span className="fs-5">{index+1}</span>
                            </div>
                        )
                        else return(
                            <div className="number rounded-circle bg-red" onClick={() => handleQuestion(index+1)}>
                                <span className="fs-5">{index+1}</span>
                            </div>
                        )
                    }
                    }
                )}
            </div>
            {!result ? (
                <div className="d-flex gap-3 align-items-center p-2">
                
                     <div className="smallcircle p-2 border border-black rounded-circle"></div>
                    <span>Actuel</span>
                    <div className="smallcircle p-2 bg-yellow rounded-circle"></div>
                    <span>Revision</span>
                    <div className="smallcircle p-2 border border-black rounded-circle bg-black"></div>
                    <span>Repondu</span>
                </div>
            ):(
                <div className="d-flex gap-3 align-items-center p-2">
                    <div className="smallcircle p-2 border bg-green rounded-circle"></div>
                    <span>Correct</span>
                    <div className="smallcircle p-2 border bg-red rounded-circle"></div>
                    <span>Incorrect</span>
                </div>
            ) }
            
            {!result && (
                <button 
                    className="button rounded-pill border-0 mt-2"
                    onClick={() => handleReviser()}
                >
                    Reviser la question
                </button>
            )}
            
        </div>
    )
})

export default QuestionLevel