import React from "react";
import './style.css'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  nom: Yup.string().required('Le nom est requis'),
  prenom: Yup.string(),
  ville: Yup.string().required('La ville est requis'),
});

const initialValues = {
  nom: '',
  prenom: '',
  ville: '',
};

const Profile = () => {

    const userId = sessionStorage.getItem("userId")
    
    
    
    
    const serverUrl = `${process.env.SERVER_DOMAIN}:${process.env.SERVER_PORT}/api/v1`;

    const handleSubmit = async (values, { setSubmitting }) => {
        
        
        
        toast.dismiss()
        toast.loading('Submitting data')
        try{
            const response = await axios.put(`${serverUrl}/auth/login`,
                {
                    ...values,
                    id: userId
                },
            );

            toast.dismiss()
            toast.success('Account updated successful')
        }catch(error){
            toast.dismiss()
            if (error.response) {
                toast.error(`Error: ${error.response.data}`);
            } else if (error.request) {
                toast.error('No response received from server.');
            } else {
                toast.error(`Error: ${error.message}`);
            }
            
        setSubmitting(false);
        }
    };

    return (
        <div className="p-5">
            <h2 className="text-center mb-4"><strong>Edit your Account</strong></h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched }) => (
                <Form style={{width: '100%', maxWidth: '560px'}} className="form-profile">
                    <div className={`form-group my-5 ${errors.nom && touched.nom ? 'has-error' : ''}`}>
                        <Field type="text" name="nom" placeHolder="*Nom" className="form-control p-3 fs-5 rounded-pill"/>
                        <ErrorMessage name="nom" component="div" className="red" />
                    </div>
                    <div className={`form-group mb-5 ${errors.prenom && touched.prenom ? 'has-error' : ''}`}>
                        <Field type="text" name="prenom" placeHolder="Prenom" className="form-control p-3 fs-5 rounded-pill"/>
                        <ErrorMessage name="prenom" component="div" className="red"/>
                    </div>
                    <div className={`form-group mb-5 ${errors.ville && touched.ville ? 'has-error' : ''}`}>
                        <Field type="text" name="ville" placeHolder="*Ville" className="form-control p-3 fs-5 rounded-pill"/>
                        <ErrorMessage name="ville" component="div" className="red"/>
                    </div>
                    <button className="rounded-pill w-100 p-3 fs-5 mx-2" type="submit" disabled={isSubmitting}>
                        Save Changes
                    </button>
                </Form>
                )}
            </Formik>
                    </div>
    )
}

export default Profile