import React from "react";
import { FaBook } from 'react-icons/fa';
import Image from '../../image/img3.webp'
import { Link } from "react-router-dom";
import './style.css';

const HomeSection2 = () => {
    return (
        <section className="section2 mb-2">
            <div className="d-flex flex-column align-items-center head">
                <h2>FORMATION</h2>
                <div>
                    <FaBook/>
                </div>
                <div className="d-flex flex-column flex-md-row  gap-5 section2-container w-lg-100">
                    <div className="flex-1 w-100" data-aos="fade-right">
                        <img src={Image} alt="tcf" className="w-100 h-100 shadow" />
                    </div>
                    <div className="flex-1 d-flex align-items-center justify-content-center" data-aos="fade-left">
                        <div className="row g-3">
                            <h3>PRÉPARATION AU TCF CANADA</h3>
                            <Link to='/comprehension-ecrite' className="button col-lg-5 col-md-11 ms-auto me-auto">Comprehension Ecrite</Link>
                            <Link to='/expression-ecrite-sujets-dactualites' className="button col-lg-5 col-md-11 ms-auto me-auto">Expression Ecrite</Link>
                            <Link to='/comprehension-orale' className="button col-lg-5 col-md-11 ms-auto me-auto">Comprehension Orale</Link>
                            <Link to='expression-orale/expression-orale-sujets-dactualites' className="button col-lg-5 col-md-11 ms-auto me-auto">Expression Orale</Link>
                            <Link to='/formations' className="button col-md-11 ms-auto me-auto">Programe de la formation</Link>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}






export default HomeSection2