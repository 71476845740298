import React from 'react'
import './style.css'
import Image from "../../image/img2.jpg";
import Image2 from "../../image/testcon.webp";

const ConnaissanceFrancais = () => {
    return (
        <main style={{lineHeight: '2rem'}}>
            <section className="section w-100 position-relative mt-0" style={{height: '30rem'}}>
                <img src={Image} alt="background Image" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3"> 
                    <h2 className="text-center mb-2 text-white"><strong>Test de Connaissance du Français pour le canada</strong></h2>
                </div>
            </section>
            <section className='px-5'>
                <div className='container shadow p-5 connaissance'>
                    <p><strong>Le TCF Canada, ou “Test de Connaissance du Français pour le Canada”, est un test de compétence linguistique en français conçu spécifiquement pour les personnes souhaitant immigrer au Canada ou demander la citoyenneté canadienne. Ce test évalue les compétences en français des candidats dans des situations de la vie quotidienne et professionnelle. Il est reconnu par Immigration, Réfugiés et Citoyenneté Canada (IRCC) et est utilisé dans le cadre du processus d’immigration et de citoyenneté.</strong></p>
                    <h4 className='blue my-5'><strong>Le TCF Canada est composé de quatre épreuves :</strong></h4>
                    <p><strong>Compréhension orale :</strong> évalue la capacité à comprendre le français parlé dans des situations variées. <strong>Compréhension écrite : </strong>évalue la capacité à comprendre des textes écrits en français sur différents sujets. <strong>Expression orale :</strong> évalue la capacité à s’exprimer en français dans des situations de communication courantes. <strong>Expression écrite :</strong> évalue la capacité à rédiger des textes en français pour transmettre des informations ou exprimer une opinion. Les candidats reçoivent un score pour chaque épreuve, et ces scores sont utilisés pour déterminer leur niveau global de compétence en français selon le Cadre européen commun de référence pour les langues (CECRL), qui va du niveau A1 (élémentaire) au niveau C2 (maîtrise). Pour passer le TCF Canada, les candidats doivent s’inscrire auprès d’un centre de test agréé et payer des frais d’inscription. Les résultats sont généralement disponibles quelques semaines après avoir passé le test. Le TCF Canada est administré par le Centre international d’études pédagogiques (CIEP), qui fait partie du ministère français de l’Éducation nationale. Le test a été spécialement conçu pour répondre aux exigences d’Immigration, Réfugiés et Citoyenneté Canada (IRCC) en matière de compétences linguistiques pour les programmes d’immigration et de citoyenneté.</p>
                    <h4 className='blue my-5'><strong>Voici quelques informations supplémentaires sur le TCF Canada :</strong></h4>
                    <p>Validité des résultats : Les résultats du TCF Canada sont valables pour une période de deux ans à partir de la date de passage du test. Les candidats doivent donc s’assurer que leur score est toujours valide lorsqu’ils soumettent leur demande d’immigration ou de citoyenneté. Préparation au test : Il existe diverses ressources pour se préparer au TCF Canada, notamment des manuels, des cours en ligne et des applications. Le CIEP propose également des exemples de sujets et des conseils pour se préparer aux différentes épreuves du test. Il est recommandé de se familiariser avec le format du test et de s’entraîner à répondre aux questions dans les conditions de l’examen. Niveaux requis pour l’immigration : Les niveaux de compétence en français requis pour l’immigration au Canada varient en fonction du programme d’immigration et des critères de sélection. Par exemple, pour le Programme des travailleurs qualifiés du Québec, un certain nombre de points sont attribués en fonction des résultats obtenus au TCF Canada. Pour la citoyenneté canadienne, les candidats doivent généralement démontrer un niveau de compétence en français au moins équivalent au niveau B1 du CECRL. Passer le test à plusieurs reprises : Les candidats peuvent passer le TCF Canada autant de fois qu’ils le souhaitent, mais ils doivent attendre au moins 30 jours entre chaque session. Cela peut être utile si vous souhaitez améliorer votre score pour augmenter vos chances d’immigration ou de citoyenneté. </p>
                    <div className='d-flex justify-content-center my-5'>
                        <img src={Image2} className='d-inline-block mx-auto' style={{width: '100%', maxWidth: '1000px'}} />
                    </div>
                </div>
            </section>
        </main>
    )
}

export default ConnaissanceFrancais