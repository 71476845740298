import React from 'react'
import './style.css'
import Image from '../../image/img5.jpg'
import { FaUserCircle, FaComment, FaShare, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Immigration = () => {

    const handleSubmit = (values, { setSubmitting }) => {
        
        
        
    
        
        setSubmitting(false);
      };

    return (
       <main className='d-flex flex-column gap-5 align-items-center p-3 immigration'>
            <div className='blogitem2 m-3 d-flex flex-column align-items-center text-center position-relative p-5'>
                <span className='position-absolute start-50 top-0 p-3 bg-primary text-white blogbrand'>BLOG</span>
                <div className="text-center">
                    <Link to=''><h3 className='fs-4 text-black'><strong>Le Role Crucial du TCF Canada dans le Parcours d'Immigration des Familles</strong></h3></Link>
                    <ul className='d-flex gap-2 fs-5 justify-content-center'>
                        <li className='d-flex gap-3 align-items-center'>
                            Par 
                            <FaUserCircle /> 
                            <Link to='' className='textgray'>TAHA TCF</Link> 
                            <Link to='' className='textgray'><FaComment/></Link> 
                            <Link to='' className='textgray'><FaShare/></Link>
                        </li>
                    </ul>
                </div>
                <div className='blogbox1 w-100 position-relative'>
                    <img src={Image} alt='blog' className='w-100 h-100 img position-absolute start-0 top-0' />
                    <Link to=''>
                        <div className='w-100 h-100 position-absolute start-0 top-0 gradient5 align-items-center justify-content-center gap-2'>
                            <div className='rounded-circle p-2 bg-white'></div>
                            <div className='rounded-circle p-2 bg-white'></div>
                            <div className='rounded-circle p-2 bg-white'></div>
                        </div>
                    </Link>
                    <div className='date position-absolute bg-white text-center'>
                        <span className='fs-3'>25</span>
                        <br />
                        <span className='fs-5'>MAR</span>
                    </div>
                </div>
                <div class="immigration-text p-2 m-1 d-flex flex-column gap-3">
			        <h1><strong><u>Le Rôle Crucial du TCF Canada dans le Parcours d’Immigration des Familles</u></strong></h1>
                    <p><strong><em>Introduction:</em></strong></p>
                    <p>L’immigration au Canada est un rêve pour de nombreuses familles à travers le monde. Ce rêve offre des opportunités de vie meilleures et plus sûres. Le TCF Canada joue un rôle central dans ce parcours. Il agit comme un pont vers la réalisation de ce rêve. Cet article explore en profondeur le rôle du TCF Canada dans le processus d’immigration des familles. Il souligne son importance, ses implications, et comment les familles peuvent se préparer efficacement pour réussir ce test essentiel.</p>
                    <h2><strong><em>&nbsp;Compréhension du TCF Canada:</em></strong></h2>
                    <p>Le TCF Canada est conçu spécifiquement pour répondre aux exigences des politiques d’immigration canadiennes. Il évalue les compétences en français des candidats dans quatre domaines : compréhension orale, compréhension écrite, expression orale, et expression écrite. La maîtrise de ces compétences est cruciale pour l’intégration sociale et professionnelle au Canada, surtout dans les provinces francophones.</p>
                    <h2><strong><em>&nbsp;Le TCF Canada : Un Critère d’Immigration pour les Familles:</em></strong></h2>
                    <p>Pour les familles désireuses d’immigrer, chaque membre adulte doit prouver sa compétence en français en passant le <span style={{color: '#0000ff'}}><Link style={{color: '#0000ff'}} to="https://tcf-canada.ca/">TCF Canada</Link></span>. Ce critère assure non seulement que les immigrants peuvent participer pleinement à la société canadienne, mais aussi qu’ils ont accès à un éventail plus large d’opportunités d’emploi.</p>
                    <h2><strong><em>&nbsp;Immigration des familles: Impact du TCF Canada:</em></strong></h2>
                    <p>Les scores obtenus au TCF Canada peuvent significativement influencer le processus d’immigration des familles, notamment dans les programmes tels que l’Entrée Express et le Programme des Candidats des Provinces (PCP). Un bon score peut augmenter les chances d’obtention de la résidence permanente, soulignant l’importance d’une préparation adéquate.</p>
                    <h2><strong><em>&nbsp;Préparer sa Famille au TCF Canada:</em></strong></h2>
                    <p>La préparation au TCF Canada devrait être une entreprise familiale, impliquant des stratégies d’étude collectives et le soutien mutuel. L’utilisation de ressources en ligne, l’inscription à des cours de langue, et la pratique quotidienne peuvent toutes contribuer à améliorer les compétences en français de chaque membre de la famille.</p>
                    <h2><strong><em>&nbsp;Immigration des familles: Conseils pour Réussir le TCF Canada&nbsp;</em></strong></h2>
                    <p>Engagement Collectif : Fixez des objectifs d’apprentissage communs et encouragez-vous mutuellement à étudier régulièrement.</p>
                    <p>Ressources Diversifiées : Combinez différentes méthodes d’apprentissage, des applications mobiles aux programmes télévisés en français.</p>
                    <p>Simulation de Test : Pratiquez avec des tests d’entraînement pour vous familiariser avec le format et le type de questions sur notre plate-forme officielle <Link to="http://www.formation-tcfcanada.com">www.formation-tcfcanada.com</Link></p>
                    <p>Immersion Linguistique : Intégrez le français dans votre vie quotidienne, en parlant français à la maison et en consommant des médias francophones.</p>
                    <p><strong><em>Conclusion:</em></strong></p>
                    <p>Le TCF Canada est plus qu’un simple test de langue; il est un catalyseur clé pour l’immigration des familles au Canada, ouvrant la porte à des opportunités illimitées. Une préparation minutieuse et un engagement familial sont essentiels pour surmonter ce défi et réussir ensemble. En reconnaissant l’importance du TCF Canada et en adoptant une approche proactive à l’apprentissage, les familles peuvent non seulement atteindre leurs objectifs d’immigration mais aussi s’assurer une intégration réussie et enrichissante dans la société canadienne.</p>						
                    <div className='box position-relative mx-auto'>
                        <img src={Image} className='w-100 h-100 img position-absolute start-0 top-0' alt='something' />
                    </div>
                    <div className='mx-auto d-flex gap-5'>
                        <span className='text-primary fs-5'>Pour plus d'informations</span>
                        <Link to='#' className="button rounded-pill">Cliquez ici</Link>
                    </div>
                </div>
                <div className='immigration-box2 p-2 m-1'>
                    <hr />
                    <div className='d-flex justify-content-between py-3 align-items-center flex-column flex-md-row gap-4'>
                        <div className='d-flex gap-3 flex-wrap justify-content-center'>
                            <Link to="#" className='text-dark'>
                                <div className='d-flex gap-3 py-2 px-3 border align-items-center box'>
                                    <div className='rounded-circle p-1 dot bg-dark'></div>
                                    <span>Canada</span>
                                </div>
                            </Link>
                            <Link to="#" className='text-dark'>
                                <div className='d-flex gap-3 py-2 px-3 border align-items-center box'>
                                    <div className='rounded-circle p-1 dot bg-dark'></div>
                                    <span>Immigration</span>
                                </div>
                            </Link>
                            <Link to="#" className='text-dark'>
                                <div className='d-flex gap-3 py-2 px-3 border align-items-center box'>
                                    <div className='rounded-circle p-1 dot bg-dark'></div>
                                    <span>Reussir Le Teste</span>
                                </div>
                            </Link>
                            <Link to="#" className='text-dark'>
                                <div className='d-flex gap-3 py-2 px-3 border align-items-center box'>
                                    <div className='rounded-circle p-1 dot bg-dark'></div>
                                    <span>Tcf</span>
                                </div>
                            </Link>
                            <Link to="#" className='text-dark'>
                                <div className='d-flex gap-3 py-2 px-3 border align-items-center box'>
                                    <div className='rounded-circle p-1 dot bg-dark'></div>
                                    <span>Tcf Canada</span>
                                </div>
                            </Link>
                        </div>
                        <div className='d-flex gap-2'>
                            <Link to="#" className='d-flex align-items-center justify-content-center fs-4 bg-primary rounded-circle text-white circle3'>
                                <FaFacebook />
                            </Link>
                            <Link to="#" className='d-flex align-items-center justify-content-center fs-4 bg-success rounded-circle text-white circle3'>
                                <FaWhatsapp />
                            </Link>
                        </div>
                    </div>
                    <div className='d-flex flex-column immigration-box3 gap-4'>
                        <h3><strong>Laisser un commentaire</strong></h3>
                        <p className='fs-5'><strong>Votre adresse e-mail ne sera pas publiée. Les champs obligatoires sont indiqués avec<span className='red'>*</span></strong></p>
                        <Formik
                            initialValues={{
                                name: '',
                                email: '',
                                website: '',
                                comment: '',
                                enregistrer: false,
                            }}
                            validationSchema={
                                Yup.object().shape({
                                name: Yup.string().required('Name is required'),
                                email: Yup.string().email('Invalid email').required('Email is required'),
                                website: Yup.string().url('Invalid URL').nullable(),
                                comment: Yup.string().required('Comment is required'),
                                enregistrer: Yup.boolean(),
                            })}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                            <Form className='d-flex flex-column gap-4'>
                                <div>
                                    <label htmlFor="comment" className='mb-2'>Comment <span className='red'>*</span></label>
                                    <br />
                                    <Field as="textarea" name="comment" className="w-100" rows={7} />
                                    <ErrorMessage name="comment" component="div" className='red'/>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-4'>
                                    <div className='flex-grow-1'>
                                        <label htmlFor="name" className='mb-2'>Name <span className='red'>*</span></label>
                                        <br />
                                        <Field type="text" name="name" className='border-black w-100 p-2' />
                                        <ErrorMessage name="name" component="div" className='red' />
                                    </div>
                                    <div className='flex-grow-1'>
                                        <label htmlFor="email" className='mb-2'>E-mail <span className='red'>*</span></label>
                                        <br />
                                        <Field type="email" name="email" className='border-black w-100 p-2'/>
                                        <ErrorMessage name="email" component="div" className='red' />
                                    </div>
                                    <div className='flex-grow-1'>
                                        <label htmlFor="website" className='mb-2'>Website</label>
                                        <br />
                                        <Field type="text" name="website" className='border-black w-100 p-2'/>
                                        <ErrorMessage name="website" component="div" className='red' />
                                    </div>
                                </div>
                                <div>
                                <label>
                                  <Field type="checkbox" name="enregistrer" />
                                  Enregistrer mon nom, mon e-mail et mon site dans le navigateur pour mon prochain commentaire.
                                </label>
                              </div>
                                <button type="submit" className='submit2 fs-5 rounded-pill px-3' disabled={isSubmitting}>
                                    LAISSER UN COMMENTAIRE
                                </button>
                            </Form>
                            )} 
                        </Formik>
                    </div>
                </div>
            </div>
       </main>
    )
}

export default Immigration