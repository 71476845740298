import React from 'react'
import './style.css'
import Image from "../../image/img2.jpg";
import Layout3 from '../../layout/Layout3';

const MethExpressionOrale = () => {
    return (
        <main className='d-flex flex-column gap-4'>
        <section className="section w-100 position-relative mt-0">
            <img src={Image} alt="background" className="position-absolute start-0 top-0 w-100 h-100" />
            <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                <div className="box5 text-white d-flex flex-column gap-2 m-5">
                    <h2 className=""><strong>la méthodologie du TCF Canada Expression Écrite</strong></h2>
                    <p className="text-justify fs-5">CL'expression écrite du TCF Canada évalue la capacité des candidats à produire un discours écrit en français.</p>
                </div>
            </div>
        </section>
        
        <Layout3 title={'Comment réussir l’expression orale du TCF Canada ?'} >
            <div class="border border-primary shadow rounded p-3 methodologie">
                <p>Êtes-vous sur le point de passer bientôt le <b>TCF Canada </b>? Nous souhaitons vous fournir des informations utiles sur l’épreuve d’expression orale. Il est important que vous compreniez les différents exercices auxquels vous serez confronté lors de l’examen et que vous soyez bien préparé pour obtenir les meilleurs résultats possibles.</p>
                <p>Si vous avez décidé de passer l’épreuve complémentaire d’expression orale lors de votre <b>TCF CANADA</b>, nous sommes là pour vous guider à travers les différentes étapes que vous devrez franchir pour réussir cette épreuve. Voici donc les explications nécessaires pour vous aider à savoir ce qui est attendu de vous le jour de l’examen.</p>
                <ul>
                    <li>Comment se structure l’épreuve d’expression orale ?</li>
                </ul>
                <ul>
                    <li>Comment est-elle notée?</li>
                    <li>Quels sont les types d’exercices que vous aurez à réaliser ?</li>
                    <li>Quels sont les conseils pour réussir cette épreuve ?</li>
                    <li>Pouvez-vous vous entraîner à l’expression orale sur la plateforme formation-tcfcanada.com ?</li>
                </ul>
                <p>Suivez nous dans ce petit tour d’horizon pour une préparation plus sereine de cette épreuve.</p>					
            </div>
        </Layout3>
        <Layout3 title={'Comment se structure la partie « expression orale » du TCF Canada ?'} >
            <div class="border border-primary shadow rounded p-3 methodologie">
                <p>L’épreuve <b>d’expression orale</b> dure douze minutes au total et se déroule en présence d’un examinateur lors d’un entretien. Au cours de cette épreuve, vous serez amené à réaliser trois exercices spécifiques.</p>
                <ul>
                    <li>Le premier est un&nbsp;<strong>entretien dirigé</strong>&nbsp;sans préparation. Il dure deux minutes. Cet exercice a pour objectif de déterminer si le candidat peut échanger avec une personne qu’il ne connaît pas.</li>
                    <li>Le deuxième exercice est en&nbsp;<strong>interaction</strong>, mais il demande de la préparation. Cette étape dure 5 minutes 30. Elle permet de voir si le candidat est apte à&nbsp;<strong>obtenir une information spécifique</strong>, dans une situation courante de la vie quotidienne.</li>
                    <li>Le troisième exercice est une&nbsp;<strong>expression sur un point de vue</strong>, sans préparation. L’épreuve dure 4 minutes 30. Cela permet de savoir si le candidat est apte à parler de manière spontanée, continue, mais également convaincante en répondant simplement à une question.</li>
                </ul>	
            </div>
        </Layout3>
        <Layout3 title={'Comment est notée l’épreuve d’expression orale du TCF Canada ?'} >
            <div class="border border-primary shadow rounded p-3 methodologie">
                <p>Pendant l’épreuve d’expression orale, l’entretien est enregistré afin d’être évalué conjointement par le centre de passation du test et une équipe de correcteurs qualifiés formés par France Éducation International.</p>
                <p>&nbsp;Cette épreuve est évaluée sur une échelle de notation de 20 points, qui est ensuite traduite en niveaux correspondant à une grille établie par le CECRL (Cadre Européen Commun de Référence pour les Langues) et par l’association ALTE (Association of Language Testers in Europe).</p>
                <p>Ces niveaux permettent aux professionnels et aux éducateurs de déterminer objectivement les compétences linguistiques d’un candidat en matière d’expression.</p>
                <h4>Rapport entre niveaux et capacités du candidat en matière d’expression orale pour le <span style={{fontWeight: 'normal'}}>TCF </span>:</h4>
                <ul>
                    <li>A1 : Aptitude à décrire en termes simples son lieu d’habitation et les gens qu’il connaît.</li>
                    <li>A2 : Aptitude à décrire en termes simples des personnes, des conditions de vie, sa formation et son activité professionnelle actuelle ou récente.</li>
                    <li>B1 :&nbsp; Aptitude à raconter de manière simple des histoires, à donner de brèves explications sur un projet ou une idée et à exprimer ses réactions.</li>
                    <li>B2 :&nbsp; Aptitude à s’exprimer de façon claire et détaillée sur une grande variété de sujets relatifs à ses centres d’intérêt, à donner un avis et à expliquer les avantages et les inconvénients d’un projet.</li>
                    <li>C1 :&nbsp; Aptitude à présenter de façon détaillée et structurée des sujets complexes, à en développer certains points et parvenir à une conclusion appropriée.</li>
                    <li>C2 :&nbsp; Aptitude à présenter une description ou une argumentation claire et structurée permettant à l’interlocuteur d’en remarquer les points essentiels.</li>
                </ul>
            </div>
        </Layout3>
        <Layout3 title={'Types d’exercices pour la partie expression orale'} >
            <div class="border border-primary shadow rounded p-3 methodologie">
                <p>Nous allons maintenant vous donner quelques exemples d’expression orale que vous pourrez retrouver le jour de votre examen :</p>
                <ul>
                    <li>Pour le premier exercice : est-ce que vous pouvez me parler de vous ? Est-ce que vous pouvez vous présenter ?</li>
                    <li>Pour le deuxième exercice : vous partez en vacances, mais malheureusement, vous ne pouvez pas emmener votre animal de compagnie avec vous. Vous êtes donc à la recherche de quelqu’un qui pourra facilement le garder. Posez-moi des questions pertinentes pour savoir si je suis en mesure de garder votre animal ou non.</li>
                    <li>Pour le troisième exercice : à votre avis, comment se porte l’économie de la France ?</li>
                </ul>
            </div>
        </Layout3>
        <Layout3 title={'Sujets d’expression orale du TCF Canada'} >
            <div class="border border-primary shadow rounded p-3 methodologie">
                <h4>L’épreuve d’expression orale comporte Trois tâches d’une durée totale de 12 minutes :</h4>
                <p>&nbsp;</p>
                <h5 className='blue'><strong>Première&nbsp;</strong>Tâche</h5>
                <p><strong>Elle consiste en un entretien avec votre examinateur, sans préparation. Vous devez pouvoir échanger avec celui-ci et répondre à des questions simples :</strong></p>
                <ul>
                    <li>Quel est votre film préféré ? Pourquoi ?</li>
                    <li>Où êtes-vous allé durant vos dernières vacances ?</li>
                    <li>Comment imaginez-vous votre vie dans 30 ans ?</li>
                    <li>Où avez-vous appris le français ?</li>
                </ul>
                <h5 className='blue'><strong>Deuxième&nbsp;</strong>Tâche</h5>
                <p><strong>Vous posez à votre examinateur des questions sur un sujet de la vie quotidienne. Cette partie est précédée d’une préparation au cours de laquelle vous notez les différentes questions à poser en rapport avec le thème que vous avez choisi. </strong><strong>Ici, vous menez la conversation.</strong></p>
                <ul>
                    <li>Je dirige une association d’aide aux personnes en difficulté. Demandez-moi comment fonctionne cette association (actions, financements, adhérents…).</li>
                    <li>Vous partez en vacances et vous recherchez quelqu’un pour garder votre chat. Je vous propose ma candidature. Posez-moi des questions pour décider si je suis capable de garder votre chat (motivation, goûts, personnalité…).</li>
                    <li>Vous vous interrogez sur l’organisation des transports en commun en France. Posez-moi toutes les questions à ce sujet (fréquence, horaires, tarifs…).</li>
                </ul>
                <h5 className='blue'><strong>Troisième&nbsp;</strong>Tâche</h5>
                <p><strong>Vous vous exprimez spontanément sur un sujet proposé. Il n’y a donc aucune préparation préalable. Vous développez vos idées et vos arguments devront être en mesure de convaincre votre examinateur.</strong></p>
                <ul>
                    <li>Faut-il à votre avis interdire la vente d’alcool aux mineurs ?</li>
                    <li>Voyager nous rend meilleurs. Êtes-vous d’accord avec cette affirmation ? Pourquoi ?</li>
                    <li>Les moyens de communication ne cessent de se développer. Les gens communiquent-ils mieux aujourd’hui ?</li>
                </ul>
            </div>
        </Layout3>
        <Layout3 title={'Conseils Pour Réussir Cette Épreuve Du TCF Canada'} >
            <div class="border border-primary shadow rounded p-3 methodologie">
                <p>Il est important de souligner l’importance d’une préparation adéquate pour chacune des épreuves du <b>TCF Canada</b><strong>.</strong> Une bonne préparation vous permettra de passer l’examen en toute confiance et d’obtenir un score élevé.</p>
                <p>Pour réussir l’épreuve d’expression orale du <b>TCF Canada</b>, nous vous recommandons vivement de vous renseigner et de vous familiariser avec le déroulement de l’épreuve. Étant donné que cette épreuve se concentre sur l’écoute et l’expression orale, vous devrez vous entraîner activement en imaginant différents petits exercices à effectuer quotidiennement.</p>
                <p>Écoutez une ou plusieurs&nbsp;<strong>chaînes françaises de radio ou de télévision&nbsp;</strong>et variez l’écoute des thèmes abordés : infos, reportages, films, bulletin météo, publicités, émissions politiques… Vous aurez ainsi accès à des niveaux de langage différents.</p>
                <ul>
                    <li>Entraînez-vous petit à petit à regarder des&nbsp;<strong>films non sous-titrés</strong>.</li>
                    <li>Entraînez-vous à comprendre un&nbsp;<strong>document audio&nbsp;</strong>donné au bout d’une seule écoute.</li>
                    <li><strong>Imaginez et répondez à des questions&nbsp;</strong>qui auraient pu être posées sur ce document.</li>
                    <li>Pensez autant que possible&nbsp;<strong>en français&nbsp;</strong>plutôt que dans votre langue maternelle.</li>
                    <li><strong>Décrivez mentalement en français&nbsp;</strong>tout ce que vous voyez autour de vous lors de vos divers déplacements.</li>
                    <li><strong>Imaginez une conversation&nbsp;</strong>entre des personnages figurant sur une photo.</li>
                    <li>Entraînez-vous à ne plus noter les mots-clés lors de l’écoute d’un document audio, mais à les&nbsp;<strong>mémoriser</strong>.</li>
                    <li>Le jour de l’examen,&nbsp;<strong>parler légèrement plus lentement&nbsp;</strong>vous aidera à calmer un stress éventuel.</li>
                    <li>Enfin, n’hésitez pas à imaginer&nbsp;<strong>tout autre petit exercice&nbsp;</strong>pouvant vous venir à l’esprit dans le cadre de votre apprentissage.</li>
                </ul>
                <h4 className='blue'><strong>Bon à savoir :</strong></h4>
                <p>L’examinateur vous expliquera le déroulement de l’épreuve en début d’entretien et s’assurera que vous l’ayez bien assimilé.</p>
                <p><strong>N’hésitez pas à faire répéter toute question que vous auriez mal comprise.</strong></p>
                <p>Référez-vous à vos expériences personnelles diverses pour étayer vos réponses ou bien imaginez-en des nouvelles.</p>
                <p>L’examinateur vous interrompra une fois la durée de l’épreuve terminée.</p>	
            </div>
        </Layout3>
    </main>
    )
}

export default MethExpressionOrale