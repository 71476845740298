import React from "react";
import HomeSection1 from "../../components/HomeSection1/HomeSection1";
import HomeSection2 from "../../components/HomeSection2/HomeSection2";
import './style.css'
import HomeSection3 from "../../components/HomeSection3/HomeSection3";
import TarifFormation from "../../components/TarifFormation/TarifFormation";
import TarifTest from "../../components/TarifTest/TarifTest";
import OurTeam from "../../components/OurTeam/OurTeam";
import HomeSection4 from "../../components/HomeSection4/HomeSection4";
import Contact from "../../components/Contact/Contact";
import Avis from "../../components/Avis/Avis";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <main>
            <HomeSection1 />
            <HomeSection2 />
            <HomeSection3 />
            <TarifFormation />
            <TarifTest />
            <OurTeam />
            <HomeSection4 />
            <Contact />
            <Avis />
            <div className="d-flex justify-content-center my-4">
                <Link to="/avis" className="button rounded-pill">Laissez-nous votre avis</Link>
            </div>
        </main>
    )
}

export default Home