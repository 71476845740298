import React, { memo, useState } from 'react'
import './style.css'

const   Question = memo(({actualQuestion, handleResponse, question={}, handleQuestion, reponse, result, oral, numQuestion, getPoint}) => {
    
    const reponses = question?.reponses ? question?.reponses : []
    const [value, setValue] = useState('')
    const handleOptionChange = (event) => {
        
        let value = event.target.value
        setValue(value);
        handleResponse(value);
    };
    
    return (
        <div>
            {oral && <h3>Question {actualQuestion} :</h3>}
            {question.img_url && (
                <div className='rounded-5 border border-primary fs-3 overflow-hidden question my-5'>
                    <img src={question.img_url} alt="Question" className='image w-100' />
                </div>
            )}
            {question.texte && <p className='text-justify'>{question.texte}</p>}
            {question.audio_url && (
                <div className='mx-auto'>
                    <audio controls className='w-100'>
                        <source src={question.audio_url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            )}
            
            {}

            <ul className='answers d-flex flex-column align-items-center gap-2 p-0 my-3'>
                {reponses.map((answer, ind) => {
                    let classes;
                    if (result) {
                        if (answer.veracite === true) {
                            classes = 'bg-green fs-4 rounded-3';
                        }
                        else if (answer?.id === reponse[actualQuestion - 1]) {
                            classes = 'bg-red fs-4 rounded-3';
                        }
                        else{
                            classes = 'answer fs-4 rounded-3';
                        }
                    }else{
                        classes = 'answer fs-4 rounded-3';
                    }
                    
                    return (
                    <li className={`${classes}`} key={ind}>
                        <label className='d-flex align-items-center gap-3 py-2 px-4 '>
                            <input 
                                className='questioninput'
                                autoComplete='off'
                                name='answer'
                                type="radio"
                                value={answer?.id}
                                checked={value === answer?.id}
                                onChange={handleOptionChange}
                                disabled={result}
                            />
                            <span className='fs-2'>{String.fromCharCode(65+ind)}</span>
                            <span className='ms-2'>{answer?.texte}</span>
                        </label>
                    </li>
                )})}
               {result && (
                    <>
                        {(reponses.find(a => a?.veracite))?.id === reponse[actualQuestion - 1] ? (
                            <li className='answer fs-4 rounded-3 py-2 px-3 text-success mt-5'>
                                Correct
                            </li>
                        ) : (
                            <li className='answer fs-4 rounded-3 py-2 px-3 text-danger mt-5'>
                                Incorrect
                            </li>
                        )}
                    </>
                )} 
                            </ul>
            {!result && (
                <div className='mx-4 d-flex justify-content-between'>
                    {actualQuestion !== 1 && (
                        <button 
                            className='button border-0 rounded-pill' 
                            onClick={() => {
                                setValue(0)
                                handleQuestion(actualQuestion - 1)
                            }}
                        > Retour </button>
                    )}
                    <button 
                        className='button border-0 rounded-pill ms-auto'
                        onClick={() => {
                            if(reponse[actualQuestion - 1]){
                                handleQuestion(actualQuestion + 1)
                                setValue('')
                            }
                        }}
                    >{actualQuestion === numQuestion ? 'Terminer' : 'Suivant' }</button>
                </div>
            )}
        </div>
    )
})
 



                
export default Question