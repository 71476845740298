import React from "react";
import './style.css';

const Layout2 = ({children, num, title}) => {
    return (
        <section style={{width: '100%', maxWidth: '1200px', }} className="shadow mx-auto p-4 layout2">
            <div className="d-flex gap-4 align-items-center">
                {num && <h1>{num}</h1>}
                
                <h2 className="blue">{title}</h2>
                <hr className="flex-grow-1" />
            </div>
            {children}
        </section>
    )
}

export default Layout2