import React from "react";




import './style.css'
import AuthentificationLayout from "../../layout/AuthentificationLayout";
import AuthContent from "../AuthContent/AuthContent";


const Authentification = ({show, option, handleClose, handleShow}) => {
    return (

        <AuthentificationLayout handleClose={handleClose} show={show}>
            <AuthContent option={option} handleShow={handleShow} />
        </AuthentificationLayout>
    )
}
export default Authentification