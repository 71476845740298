import React from "react";
import './style.css'
import { Link } from "react-router-dom";

const Tarif = ({title, price, temp, items, brand, link, access}) => {
    
    return (
        <div style={{minHeight: temp ? '940px' : ''}} className="rounded-5 shadow text-center overflow-hidden border option d-flex flex-column">
            <div className="title d-flex justify-content-center align-items-center position-relative bgblue">
                <h3 className="fs-2 font-weight-bold">{title}</h3>
                
                {brand && (
                    <div className="box2 position-absolute top-0 end-0 d-flex align-items-center">
                        <div style={{background: brand.color}} className="brand text-center">{brand.text}</div>
                    </div>
                )}
            </div>
            <div className="body d-flex flex-column align-items-center justify-content-between ">
                <div>
                    <div className="mb-3 mt-3">
                        <h3 className="font-price">$<span>{price}</span></h3>
                        {temp && <h4 className="fs-2 blue">{temp}</h4>}
                    </div>
                    <ul>
                        {items.map((item, idx) => (
                            <li key={idx} dangerouslySetInnerHTML={{ __html: item }} className="pt-4 pb-4 fs-lg-5 fs-6"/>
                        ))}
                        {access && <span className='fs-5 m-3'><strong>{access}</strong></span>}
                    </ul>
                </div>
                <Link to={link} className="button rounded-pill mt-3 mb-3">S'ABONNER</Link>
            </div>
        </div>
    )
}


export default Tarif