import React, { useState } from "react";
import { FaWhatsapp, FaTimes } from "react-icons/fa";
import './style.css'
import { Link } from "react-router-dom";

const Whatsapp = () => {

    const [isWhatsappOpen, setIsWhatsappOpen] = useState(false)

    const handleClose = () => {
        setIsWhatsappOpen(false)
    }

    const handleOpen = () => {
        setIsWhatsappOpen(true)
    }

    return (
        <>  
            { isWhatsappOpen && (
                <div className="whatsapp-box d-flex flex-column position-fixed shadow">
                    <div className="bggreen text-white p-3 pb-5 d-flex  gap-4">
                        <FaWhatsapp className="fs_icon ms-2" />
                        <span className="fs-3">Commencer une conversation</span>
                    </div>
                    
                    <div className="px-3 py-4 d-flex flex-column gap-3 bg-white">
                        <Link to="https://wa.me/+237693329888" target="blank">
                            <div className="d-flex px-3 py-4 align-items-center gap-4 box">
                                <div className="fs_icon bggreen rounded-circle zapcircle d-flex">
                                    <FaWhatsapp className="font_icon text-white" />
                                </div>
                                <div className="flex-grow-1 d-flex flex-column text-black">
                                    <h3 className="fs-5">tcfcanada</h3>
                                    <span className="fs-6">tcfcanada</span>
                                </div>
                                <FaWhatsapp className="fs-2 text-success" />
                            </div>
                        </Link>
                        <Link to="https://chat.whatsapp.com/GwtKZiEmdPC0csy3bAnn8x" target="blank">
                            <div className="d-flex px-3 py-4 align-items-center gap-4 box">
                                <div className="fs_icon bggreen rounded-circle zapcircle d-flex">
                                    <FaWhatsapp className="font_icon text-white" />
                                </div>
                                <div className="flex-grow-1 d-flex flex-column text-black">
                                    <h3 className="fs-5">Forum de preparation</h3>
                                    <span className="fs-6">Forum de preparation TCF Canada</span>
                                </div>
                                <FaWhatsapp className="fs-2 text-success" />
                            </div>
                        </Link>
                    </div>
                </div>
            )}
            
            {isWhatsappOpen ? (
                    <div onClick={handleClose} className="whatsapp-icon position-fixed d-flex justify-content-center align-items-center rounded-circle">
                        <FaTimes className="fs_icon text-white" />
                    </div>
                ) : (
                    <div onClick={handleOpen} className="whatsapp-icon position-fixed d-flex justify-content-center align-items-center rounded-circle">
                        <FaWhatsapp className="fs_icon text-white" />
                    </div>
                )
            }
            
        </>
    )
}

export default Whatsapp