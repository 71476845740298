import React from "react";
import './style.css';
import Image from '../../image/img6.jpg'
import { FaTimes } from "react-icons/fa";

const AuthentificationLayout = ({children, handleClose, show}) => {
    return (
        <>
            {show && (
                <div className="authentification position-fixed vw-100 vh-100 start-0 top-0 gradient5 d-flex justify-content-center align-items-center" data-aos="zoom-out">
                            
                    <div className="box7 m-5 d-flex position-relative">
                        <div className="bg-white close rounded-circle d-flex align-items-center justify-content-center position-absolute top-0 end-0 m-2" onClick={() => handleClose()}>
                            <FaTimes className="fs-3"/>
                        </div>
                        <div className="img d-none d-md-flex">
                            <img src={Image} alt="" className="w-100 h-100"/>
                        </div>
                        {children}
                    </div>
                </div>
            )}
        </>
    )
}

export default AuthentificationLayout