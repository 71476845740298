import React, { useState } from "react";
import { BiGrid  } from 'react-icons/bi';
import { MdApps, MdViewComfy } from 'react-icons/md';
import Image from '../../image/test-tcf-logo.webp'
import ImageActive from '../../image/logo2.webp'
import { AiOutlineShoppingCart } from 'react-icons/ai';
import './style.css';
import { useNavigate } from "react-router-dom";

const Boutique = () => {
    const data = [
        {title: "Bronze", subtitle: "Pack", price: "14,99"},
        {title: "Essentiel", subtitle: "Pack", price: "79,99"},
        {title: "Gold", subtitle: "Pack", price: "49,99"},
        {title: "Platinium", subtitle: "Pack", price: "169,99", active: true},
        {title: "Premium", subtitle: "Pack", price: "129,99"},
        {title: "Silver", subtitle: "Pack", price: "29,99"},
        {title: "Standard", subtitle: "Pack", price: "99,99"}
    ]
    const navigate = useNavigate()
    const goto = (title) => {
        navigate('/')
    }
    const [row, setRow] = useState(3);

    return (
        <main className="container px-3 mx-auto boutique mb-5">
            <div className="d-flex justify-content-end align-items-center py-4 gap-4">
                <p className="fs-5 m-0 d-none d-lg-flex"><strong>show:</strong> <span>9</span> / <span>12</span> / <span>18</span> / <span>24</span></p>
                <div className="d-none d-lg-flex gap-2 fs-3 align-items-center ">
                    <MdApps onClick={() => setRow(2)} /> 
                    <MdViewComfy onClick={() => setRow(3)} />  
                    <BiGrid onClick={() => setRow(4)} />
                </div>
                <select id="tri" name="tri" className="fs-5 bg-white p-2 border-0">
                    <option value="1">Tri par default</option>
                    <option value="2">Tri par popularite</option>
                    <option value="3">Tri par note moyennes</option>
                    <option value="4">Tri du plus recent au plus ancien</option>
                    <option value="5">Tri par tarif croissant</option>
                    <option value="6">Tri par tarid decroissant</option>
                </select>
            </div>
            <div className="d-flex flex-wrap boutique-container">
                    {data.map((item, ind) => (
                        <div key={ind} className="p-2 boutique-box" style={{width: `${100/row}%`}} onClick={() => goto(item.title)}>
                            <div className="h-100 bg p-4 d-flex flex-column justify-content-between" style={{background: `url(${item?.active ? ImageActive : Image})`}}>
                                <div>
                                    <span className="fs-5"><strong>{item.title}</strong></span>
                                    <br />
                                    <span>{item.subtitle}</span>
                                </div>
                                <div className="d-flex justify-content-between" >
                                    <span className="fs-4 blue">${item.price}</span>
                                    <div className="p-2 shadow rounded-circle shop d-flex d-lg-none bg-white">
                                        <AiOutlineShoppingCart className="fs-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    ))}
            </div>
        </main>
    )
}

export default Boutique