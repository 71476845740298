import React from "react";
import './style.css';

const Layout3 = ({children, num, title}) => {
    return (
        <section style={{width: '100%', maxWidth: '1200px', }} className="shadow mx-auto p-4 layout2 my-4">
            <div className="d-flex gap-4 align-items-center mb-5">
                <hr className="flex-grow-1 dashed" />
                <h2 className="blue">{title}</h2>
                <hr className="flex-grow-1 dashed" />
            </div>
            {children}
        </section>
    )
}

export default Layout3