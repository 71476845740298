import React from 'react';
import { FaExclamationTriangle, FaHome, FaSyncAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

const ErrorSection = ({ errorCode }) => {

    const navigate = useNavigate()

    const renderMessage = () => {
        if (errorCode === 400) {
        return "Une Erreur est survenue pendant l'execution de votre demande.";
        }  else {
        return "Une erreur inconnue s'est produite.";
        }
    };


    return (
        <div style={{ padding: '20px', border: '1px solid red', borderRadius: '5px', margin: '30px' }}>
        <h2><FaExclamationTriangle className='text-danger' /> </h2>
        <p className='text-danger fs-4 text-justify mt-4'>{renderMessage()}</p>
        <div className='d-flex align-items-center  flex-column flex-sm-row gap-4 mx-5'>
            <button 
                className='button rounded-pill text-white border border-primary bg-primary '
                onClick={() => navigate(0)}
            >Ressayer <FaSyncAlt /></button>
        
            <Link to='/' className="fs-3">Page D'acceul <FaHome /></Link>
        </div>
        </div>
    );
};

export default ErrorSection;
