import React, { useState } from "react";
import Image from "../../image/img2.jpg";
import DisplayPrepare from "../../components/DisplayPrepare/DisplayPrepare";
import { FaTimes } from "react-icons/fa";
import './style.css';
import Subscription from "../../components/Subscription/Subscription";
import { useParams } from "react-router-dom";
import useAxiosFetch from "../../Hooks/useAxiosFetch";
import Loading from "../../components/Loading/Loading";
import ErrorSection from "../../components/ErrorSection/ErrorSection";

const TableauDeBordRf = () => {
    const [attention, setAttention] = useState(true);
    const userId = sessionStorage.getItem("userId");
    const { id1, id2 } = useParams();

    const { data: data1, isFetching: isFetching1, status: status1 } = useAxiosFetch(`training/get/${userId}/s/${id1}`);
    const { data: data2, isFetching: isFetching2, status: status2 } = useAxiosFetch(`training/get/${userId}/s/${id2}`);
    //console.log(data1)

    
    
    
    
    

    
    
    
    
    

    const modules1 = data1.modules ? data1.modules : [];
    const modules2= data2.modules ? data2.modules : [];

    
   
    

    return (
        <main className="">
            <section className="section w-100 position-relative mt-0">
                <img src={Image} alt="background" className="position-absolute start-0 top-0 w-100 h-100" />
                <div className="position-absolute start-0 top-0 w-100 h-100 d-flex justify-content-center align-items-center gradient3">
                    <div className="box5 text-white d-flex flex-column gap-5 m-5">
                        <h2 className="text-center mb-2"><strong>Plus de 1000 questions pour préparer votre Tcf </strong></h2>
                        <p className="text-center fs-5">Des tests élaborés par des examinateurs afin de vous aider à vous entraîner dans les conditions réelles de l'examen du TCF et d'assurer votre réussite.</p>
                    </div>
                </div>
            </section>
            <section className="correction1 mx-auto mb-5 px-3">
                {attention && (
                    <div className="tableau-attention p-3 position-relative my-5">
                        <h4><strong>Attention</strong></h4>
                        <p className="fs-6 text-start">Le partage de compte entre utilisateurs est strictement interdit et peut entraîner systématiquement une suppression irréversible de votre compte !</p>
                        <div className="close rounded-circle d-flex align-items-center justify-content-center position-absolute top-0 end-0 m-4" onClick={() => setAttention(false)}>
                            <FaTimes className="fs-5" />
                        </div>
                    </div>
                )}
                <Subscription />
                {isFetching1 || isFetching2 ? (
                    <Loading />
                ): status1 >= 400 || status2 >= 400 ? (
                    <>
                        {status1 >= 400 && <ErrorSection errorCode={status1} />}
                        {status2 >= 400 && <ErrorSection errorCode={status2} />}
                        
                    </>
                ):(
                    <>
                        <div className="d-flex gap-5 mt-5">
                            <hr className="flex-grow-1" />
                            <h3 className="blue">{data1.intitule} PRO</h3>
                            <hr className="flex-grow-1" />
                        </div>
                        {modules1.length > 0 && <DisplayPrepare modules={modules1} nAccessible={3} link={'comprehension-ecrite-test'} />}
                        
                        <div className="d-flex gap-5 mt-5">
                            <hr className="flex-grow-1" />
                            <h3 className="blue">{data2.intitule} PRO</h3>
                            <hr className="flex-grow-1" />
                        </div>
                        {modules2.length > 0 && <DisplayPrepare modules={modules2} nAccessible={3} link={'comprehension-oral-test'} />}
                    </>
                )}
                
            </section>
        </main>
    );
};

export default TableauDeBordRf;

                    


