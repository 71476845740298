import React from "react";
import TarifFormation from "../../components/TarifFormation/TarifFormation";
import TarifTest from "../../components/TarifTest/TarifTest";
import Layout2 from "../../layout/Layout2";
import './style.css'
import HeroLayout from "../../layout/HeroLayout";
import { Link } from "react-router-dom";

const ExpressionOrale = () => {
    return (
        <main>
            <HeroLayout>
                <div className="d-flex flex-column gap-5 text-white align-items-center">
                    <h1 className="fs-1">Expression Orale</h1>
                    <p className="fs-3" style={{maxWidth: '75rem', width: '90%'}}>Afin de vous préparer au mieux au TCF et booster votre score, nous mettons à votre disposition un test qui réunit les mêmes conditions que lors d’une session officielle</p>
                    <div className="d-flex gap-4 flex-md-row flex-column">
                        <Link className="button rounded-pill" to='/expression-orale/expression-orale-sujets-dactualites'>Sujets d'actualites</Link>
                        <Link className="button rounded-pill" to=''>S'entrainer</Link>
                    </div>
                </div>
            </HeroLayout>
            <Layout2 num={'01'} title={'Comment réussir l’expression orale du TCF Canada ?'}>
                <div class="pt-2">
                    <p>Vous allez bientôt&nbsp;passer le <span className="blue"><strong>TCF Canada</strong>&nbsp;</span>? Nous allons vous donner quelques informations concernant&nbsp;<strong>l’expression orale&nbsp;</strong>! En effet, vous devez comprendre les différents exercices que vous allez devoir passer lors de votre examen. De plus, nous vous aiderons à vous préparer pour que ce dernier se passe pour le mieux !</p>
                    <p>Votre Test de Connaissance du Français approche et vous avez choisi de passer l’épreuve complémentaire d’<strong>expression orale</strong>&nbsp;?</p>
                    <p>Afin de vous aider à comprendre ce que l’on attend de vous le jour de l’examen, nous vous expliquons les étapes à connaître et à franchir pour que cette épreuve soit une réussite :</p>
                    <ul>
                        <li>Comment se structure l’épreuve d’expression orale ?</li>
                        <li>Comment est-elle notée ?</li>
                        <li>Quels sont les types d’exercices que vous aurez à réaliser ?</li>
                        <li>Quels sont les conseils pour réussir cette épreuve ?</li>
                        <li>Pouvez-vous vous entraîner à l’expression orale sur la plateforme <strong>Tcfcatest.com</strong>&nbsp;?</li>
                    </ul>
                    <p>Suivez-nous dans ce petit tour d’horizon pour une préparation plus sereine de cette épreuve.</p>						
                </div>
            </Layout2>
            <Layout2 num={'02'} title={'Comment se structure l\'épreuve « expression orale » du TCF Canada ?'}>
                <div class="pt-2">
					<p>L’expression orale est une épreuve qui dure <u><strong>12&nbsp;minutes </strong></u>en totalité. Vous serez face à un examinateur(trice) puisqu’il s’agit d’une épreuve que l’on nomme «&nbsp;<strong>entretien&nbsp;</strong>». Vous aurez donc<strong> trois &nbsp;tâches</strong> à réaliser :</p>
                    <ul>
                        <li><strong><u><em>La première tache</em></u></strong> est un&nbsp;<strong>entretien dirigé</strong> sans préparation. Il dure <u><strong>2 minutes</strong></u>. Cet exercice a pour objectif de déterminer si le candidat peut échanger avec une personne qu’il ne connaît pas.</li>
                        <li><strong><u><em>La deuxième tache</em></u> </strong>est en <strong>interaction</strong>, mais il demande de la préparation. Cette étape dure <u><strong>5 minutes 30s</strong></u>. Elle permet de voir si le candidat est apte à <strong>obtenir une information spécifique</strong>, dans une situation courante de la vie quotidienne.</li>
                        <li><strong><u><em>La troisième tache</em></u></strong> est une <strong>expression sur un point de vue</strong>, sans préparation. L’épreuve qui dure <u><strong>4 minutes 30s</strong></u>. Cela permet de savoir si le candidat est apte à parler de manière spontanée, continue, mais également convaincante en répondant simplement à une question.</li>
                    </ul>						
                </div>
            </Layout2>
            <Layout2 num={'03'} title={'Comment est notée l’épreuve d’expression orale du TCF Canada ?'}>
                <div class="pt-2">
                    <p>Durant l’épreuve d’expression orale,&nbsp;<strong>l’entretien est enregistré pour être évalué conjointement par le centre de passation du test et par une équipe de correcteurs dûment formés par France Éducation International</strong>.</p><p>Cette épreuve est notée sur&nbsp;<strong>20 points.</strong>&nbsp;Le nombre de points obtenus est ensuite traduit en niveaux qui s’inscrivent dans une grille définie par le CECRL (Cadre Européen Commun de Référence pour les Langues) et par l’association ALTE (Association of Language Testers in Europe).</p>
                    <p>Ces niveaux permettent aux acteurs du monde professionnel et/ou éducatif de définir objectivement les capacités d’expression d’un candidat.</p>
                    <h3 class="fusion-responsive-typography-calculated" data-fontsize="20" data-lineheight="26.6px">Rapport entre niveaux et capacités du candidat en matière d’expression orale pour le TCF :</h3>
                    <ul>
                        <li>A1&nbsp; : Aptitude à décrire en termes simples son lieu d’habitation et les gens qu’il connaît.</li>
                        <li>A2 : Aptitude à décrire en termes simples des personnes, des conditions de vie, sa formation et son activité professionnelle actuelle ou récente.</li>
                        <li>B1 :&nbsp; Aptitude à raconter de manière simple des histoires, à donner de brèves explications sur un projet ou une idée et à exprimer ses réactions.</li>
                        <li>B2 :&nbsp; Aptitude à s’exprimer de façon claire et détaillée sur une grande variété de sujets relatifs à ses centres d’intérêt, à donner un avis et à expliquer les avantages et les inconvénients d’un projet.</li>
                        <li>C1 :&nbsp; Aptitude à présenter de façon détaillée et structurée des sujets complexes, à en développer certains points et parvenir à une conclusion appropriée.</li>
                        <li>C2 :&nbsp; Aptitude à présenter une description ou une argumentation claire et structurée permettant à l’interlocuteur d’en remarquer les points essentiels.</li>
                    </ul>						
                </div>
            </Layout2>
            <Layout2 num={'04'} title={'L’évaluation de L\'épreuve d’expression orale'}>
                <div class="pt-2">
                    <p><em><strong>L</strong></em>es épreuves d’expression orale sont évaluées une première fois par l’examinateur qui a suivi une formation d’habilitation dispensée par France Éducation international et qui mène et enregistre l’entretien dans le centre le jour de la passation. L’enregistrement est ensuite envoyé à un correcteur également formé par France Éducation international.&nbsp;
                    <br />
                    <em><strong>C</strong></em>es 2 évaluations sont effectuées de manière totalement indépendante et en double aveugle (sans que l’un ou l’autre n’ait connaissance des niveaux attribués par l’autre).</p>
                    <p><strong>Les critères d’évaluation de cette épreuve d’expression orale sont d’ordre :</strong></p>
                    <ul>
                        <li>linguistique (étendue et maîtrise du lexique, correction grammaticale, aisance, prononciation, fluidité globale du discours…) ;</li>
                        <li>pragmatique (interaction, structuration du discours, cohérence et cohésion, développement thématique…) ;&nbsp;</li>
                        <li>sociolinguistique (adéquation à la situation de communication).</li>
                    </ul>						
                </div>
            </Layout2>
            <Layout2 num={'05'} title={'Sujets d’expression orale du TCF Canada'}>
                <div class="elementor-widget-container">
                    <h4>L’épreuve d’expression orale comporte Trois tâches d’une durée totale de <u>12 minutes</u> :</h4>
                    <h5><span className="blue"><strong>Première&nbsp;</strong>Tâche (2min)</span></h5>
                    <p><strong>Elle consiste en un entretien avec votre examinateur, sans préparation. Vous devez pouvoir échanger avec celui-ci et répondre à des questions simples :</strong></p>
                    <ul>
                        <li>Pouvez-vous vous présenter?</li>
                        <li>Quel est votre film préféré ? Pourquoi ?</li>
                        <li>Où êtes-vous allé durant vos dernières vacances ?</li>
                        <li>Comment imaginez-vous votre vie dans 30 ans ?</li>
                        <li>Où avez-vous appris le français ?</li>
                    </ul>
                    <h5><span className="blue"><strong>Deuxième&nbsp;</strong>Tâche (5min 30s)</span></h5>
                    <p><strong>Vous posez à votre examinateur des questions sur un sujet de la vie quotidienne. Cette partie est précédée d’une préparation au cours de laquelle vous notez les différentes questions à poser en rapport avec le thème que vous avez choisi. Ici, vous menez la conversation.</strong></p>
                    <ul>
                        <li>Je dirige une association d’aide aux personnes en difficulté. Demandez-moi comment fonctionne cette association (actions, financements, adhérents…).</li>
                        <li>Vous partez en vacances et vous recherchez quelqu’un pour garder votre chat. Je vous propose ma candidature. Posez-moi des questions pour décider si je suis capable de garder votre chat (motivation, goûts, personnalité…).</li>
                        <li>Vous vous interrogez sur l’organisation des transports en commun en France. Posez-moi toutes les questions à ce sujet (fréquence, horaires, tarifs…).</li>
                    </ul>
                    <h5><span className="blue"><strong>Troisième&nbsp;</strong>Tâche (4min 30s)</span></h5>
                    <p><strong>Vous vous exprimez spontanément sur un sujet proposé. Il n’y a donc aucune préparation préalable. Vous développez vos idées et vos arguments devront être en mesure de convaincre votre examinateur.</strong></p>
                    <ul>
                        <li>Faut-il à votre avis interdire la vente d’alcool aux mineurs ?</li>
                        <li>Voyager nous rend heureux. Êtes-vous d’accord avec cette affirmation ? Pourquoi ?</li>
                        <li>Les moyens de communication ne cessent de se développer. Les gens communiquent-ils mieux aujourd’hui ?</li>
                    </ul>						
                </div>
            </Layout2>
            
            <TarifFormation />
            <TarifTest />
        </main>
    )
}

export default ExpressionOrale