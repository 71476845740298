import React, { useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Missing from './pages/Missing';
import ExpressionEcrite from './pages/ExpressionEcrite';
import ExpressionOrale from './pages/ExpressionOrale';
import ComprehensionEcrite from './pages/ComprehensionEcrite';
import ComprehensionOrale from './pages/ComprehensionOrale';
import Login from './pages/Login';
import Formation from './pages/Formation';
import Tarif from './pages/Tarif';
import PolitiqueRetour from './pages/PolitiqueRetour';
import PolitiqueConfidentialite from './pages/PolitiqueConfidentialite';
import Whatsapp from './components/Whatsapp/Whatsapp';
import MethExpressionOrale from './pages/MethExpressionOrale';

import ExpressionOraleSujet from './pages/ExpressionOraleSujet';
import ExpressionEcriteSujet from './pages/ExpressionEcriteSujet';
import ExpressionEcritePlateForme from './pages/ExpressionEcritePlateForme';
import MethExpressionEcrite from './pages/MethExpressionEcrite';
import ConnaissanceFrancais from './pages/ConnaissanceFrancais';

import Immigration from './pages/Immigration';
import CorrectionExpressionOrale from './pages/CorrectionExpressionOrale';
import CorrectionEpreuve1 from './pages/CorrectionEpreuve1';
import TableauDeBordRf from './pages/TableauDeBordRf';
import ComprehensionEcriteTest from './pages/ComprehensionEcriteTest';
import ComprehensionOraleTest from './pages/ComprehensionOraleTest';
import SujetExpressionOral from './pages/SujetExpressionOral';
import SujetExpressionEcrite from './pages/SujetExpressionEcrite';
import PourquoiTCF from './pages/PourquoiTCF';

import Panier from './pages/Panier';
import Boutique from './pages/Boutique';
import MonCompte from './pages/MonCompte';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { getToken } from './observable/tokenObservable';

const App = () => {
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userId"); 
  const navigate = useNavigate();

   useEffect(() => {
     const subscription = getToken().subscribe(token => {
       if (!token || !userId) navigate('/login');
     });

     return () => subscription.unsubscribe();
   }, [navigate, userId, token]);

   useEffect(() => {
    const clearSessionStorage = () => {
      const navEntries = performance.getEntriesByType('navigation');
      if (navEntries.length > 0 && navEntries[0].type !== 'reload') {
        sessionStorage.clear();
      }
    };
  
    window.addEventListener('beforeunload', clearSessionStorage);
    return () => {
      window.removeEventListener('beforeunload', clearSessionStorage);
    };
  }, []);
  


  useEffect(() => {
    AOS.init({
      duration: 1200,
      offset: 200,
    });
  }, []);

  return (
    <div className='app'>
      <Navbar />
      <Routes>
        <Route exact path='/' Component={Home} />
        <Route exact path='/tableau-de-bord-rf/:id1/:id2' Component={TableauDeBordRf} />
        <Route exact path='/formations' Component={Formation} />
        <Route exact path='/tarif' Component={Tarif} />
        <Route exact path='/immigration-des-familles-role-du-tcf' Component={Immigration} />
        <Route exact path='/correction-des-sujets-dexpression-orale' Component={CorrectionExpressionOrale} />
        <Route exact path='/correction-des-sujets-dexpression-orale/:date' Component={CorrectionEpreuve1} />
        <Route exact path='/la-methodologie-dexpression-orale-tcf-canada' Component={MethExpressionOrale} />
        <Route exact path='/expression-orale/expression-orale-sujets-dactualites' Component={ExpressionOraleSujet} />
        <Route exact path='/expression-ecrite-sujets-dactualites' Component={ExpressionEcriteSujet} />
        <Route exact path='/expression-ecrite-plate-forme-etape-1' Component={ExpressionEcritePlateForme} />
        <Route exact path='/la-methodologie-dexpression-ecrite-tcf-canada' Component={MethExpressionEcrite} />
        <Route exact path='/expression-ecrite' Component={ExpressionEcrite} />
        <Route exact path='/expression-orale' Component={ExpressionOrale} />
        <Route exact path='/comprehension-ecrite' Component={ComprehensionEcrite} />
        <Route exact path='/comprehension-orale' Component={ComprehensionOrale} />
        <Route exact path='/login' Component={Login} />
        <Route exact path='/politique-de-retour' Component={PolitiqueRetour} />
        <Route exact path='/politique-de-confidentialite' Component={PolitiqueConfidentialite} />
        <Route exact path='/tcf-canada-test-de-connaissance-du-francais-pour-le-canada' Component={ConnaissanceFrancais} />
        <Route exact path='/pourquoi-choisir-notre-plate-forme-tcf-canada' Component={PourquoiTCF} />
        <Route exact path='/comprehension-ecrite-test/:id' Component={ComprehensionEcriteTest} />
        <Route exact path='/comprehension-oral-test/:id' Component={ComprehensionOraleTest} />
        <Route exact path='/sujet-expression-orale/:date' Component={SujetExpressionOral} />
        <Route exact path='/sujet-expression-ecrite/:date' Component={SujetExpressionEcrite} />
        <Route exact path='/panier' Component={Panier} />
        <Route exact path='/boutique' Component={Boutique} />
        <Route exact path='/mon-compte/*' Component={MonCompte} />
        <Route path='/*' Component={Missing} />
      </Routes>
      <Whatsapp />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;















