import React from "react";
import './style.css'

const HomeSection4 = () => {
    return (
        <section className="section5 text-center text-white position-relative">
            <div className="gradient2 w-100 h-100 pt-4 position-absolute">
                <h2>Toujours prêt pour vos besoins</h2>
                <p>N'hésitez pas à nous contacter où que vous soyez, nous vous répondrons.</p>
            </div>
        </section>
    )
}

export default HomeSection4